<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="col-md-12">
        <form class="profile-form">
          <fieldset class="form-entry">
            <label for="full-name-input"><i class="fas fa-pen-alt"></i> Full Name</label>
            <input id="full-name-input" type="text" class="form-control" placeholder="Full Name" v-model="fullName">
          </fieldset>

          <fieldset class="form-entry">
            <label for="preferred-name-input"><i class="fas fa-pen-alt"></i> Preferred Name</label>
            <input id="preferred-name-input" class="form-control" placeholder="Preferred Name" v-model="preferredName">
          </fieldset>
        </form>
      </div>
      <div class="col-md-12 checkbox-container">
        <label for="opt-in-comms-check" class="comms-checkbox">
          <input id="opt-in-comms-check" type="checkbox" v-model="optInCommunication">
          I would like to opt in to receive communication regarding KATE
        </label>
      </div>
      <div class="col-md-12">
        <button :disabled="isLoading" class="submit-button btn btn-success save-button" @click="updateUserInfo">
          <i :class="isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-check'"></i>
          Save Changes
        </button>
      </div>
    </div>
</div>
</template>

<style scoped>
.submit-button {
  margin-top: 25px;
}

.form-entry {
  margin: 10px 0;
  flex: 0 0 48%;
}

.form-entry input {
  display: inline-flex;
  background-color: var(--input-background);
  color: var(--kate-type-light);
  margin-right: 3vh;
}

.profile-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox-container > label {
  margin: 15px 0;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .profile-form {
    flex-direction: column;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';
import useLoadingStore from '../stores/loading';
import ErrorMixin from '../mixins/error-mixins';

export default {
  mixins: [ErrorMixin],
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      store: useGlobalStore(),
      loading: useLoadingStore(),
      fullName: null,
      preferredName: null,
      optInCommunication: null,
    };
  },
  beforeMount() {
    this.getUserInfo();
  },
  watch: {
    userInfo() {
      this.getUserInfo();
    },
  },
  computed: {
    isLoading() {
      return this.loading.isLoading;
    },
  },
  methods: {
    getUserInfo() {
      const data = { ...this.userInfo };
      this.fullName = data.full_name;
      this.preferredName = data.preferred_name;
      this.optInCommunication = data.opt_in_communication;
    },
    updateUserInfo() {
      this.$logger.info('Updating user info', undefined, true);
      this.$Loading.start();
      this.$http.put('/api/profile/user', {
        full_name: this.fullName,
        preferred_name: this.preferredName,
        opt_in_communication: this.optInCommunication,
      }).then(() => {
        this.$logger.info('Updated user info', undefined, true);
        this.$emit('update'); // to reload profile
        this.$ktoast.success('Profile updated');
        this.store.updatePreferredName(this.preferredName);
      }).catch(err => {
        this.$logger.error('Error updating user info', undefined, err);
        this.showError(err, true);
      });
    },
  },
};
</script>

<template>
  <div class="panel panel-default">
    <div class="leaderboard-container" v-if="leaderboard.length > 0">
      <div class="panel-header">
        <h4>See how you've performed against others in your programme on this assignment.</h4>
      </div>
      <div class="panel-body leaderboard-table">
        <k-table
          :headers="kableHeaders"
          :rows="kableRows"
          :max="20"
          :hideable="false"
          :class-func="getRowClass"
          :panel="false"></k-table>
      </div>
    </div>
    <div v-else class="leaderboard-placeholder">
      <h4>No submissions have been made yet.</h4>
    </div>
  </div>
</template>

<style>
.leaderboard-table .k-table-header-ranking .k-table-header-button-block,
.leaderboard-table .table-btn-container {
  display: none;
}

.leaderboard-table .k-table-key-score,
.leaderboard-table td.k-table-string.k-table-key-ranking {
  text-align: center;
}

.leaderboard-table .k-table-container th.k-table-header-ranking {
  min-width: 80px;
}

.leaderboard-table td.k-table-string.k-table-key-ranking {
  width: 110px;
}

.leaderboard-table .k-table-header-text i {
  margin-right: 5px;
}

.leaderboard-table .k-table-key-score {
  width: 150px;
}

.leaderboard-table .k-table-key-score.k-poor-score,
.leaderboard-table .k-table-key-score.k-warning,
.leaderboard-table .k-table-key-score.k-success {
  background-color: transparent;
  color: unset;
}

.leaderboard-table tbody tr.k-table-row.current-user {
  background-color: var(--kate-type-accent);
}

.leaderboard-table tbody tr.k-table-row.current-user td {
  color: var(--kate-type-dark);
}
</style>

<style scoped>
.panel-header {
  padding: 15px;
}

.leaderboard-placeholder {
  min-height: 50vh;
  text-align: center;
  padding: 15px;
  position: relative;
}

.leaderboard-placeholder h4 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
</style>

<script>
import KTable from '../components/k-table.vue';
import ErrorMixin from '../mixins/error-mixins';

export default {
  components: {
    'k-table': KTable,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      leaderboard: [],
      currentUser: undefined,
    };
  },

  beforeMount() {
    this.getLeaderboard();
  },

  computed: {
    modulePakId() {
      return this.$route.params.modulePakId;
    },

    kableHeaders() {
      return {
        ranking: {
          name: '<i class="fas fa-medal"></i> Ranking',
          sortable: false,
        },
        name: {
          name: '<i class="fas fa-user"></i> Name',
          sortable: true,
          hideable: false,
        },
        last_submission: {
          name: '<i class="fas fa-clock"></i> Last Activity',
          sortable: true,
          type: 'timestamp',
        },
        score: {
          type: 'percentage',
          name: '<i class="fas fa-percent"></i> Score',
          sortable: true,
          options: { color: true },
        },
      };
    },

    kableRows() {
      const output = this.leaderboard.map(row => Object({
        ranking: row.ranking,
        name: row.name,
        last_submission: row.last_submission,
        score: row.score,
      }));
      return output;
    },
  },

  methods: {
    getLeaderboard() {
      this.$logger.info('Getting leaderboard for pak', { modulePakId: this.modulePakId }, true);
      this.$Loading.start();
      this.$http.get(`/api/curriculum/pak/${this.modulePakId}/leaderboard`)
        .then(response => {
          this.$logger.info('Got leaderboard for pak', { modulePakId: this.modulePakId });
          this.leaderboard = response.data.leaderboard;
          this.currentUser = response.data.current_user;
        }).catch(error => {
          if (error.response.status !== 404) {
            this.$logger.error('Error getting leaderboard for pak', { modulePakId: this.modulePakId }, error);
            this.showError(error, true);
          } else {
            this.$logger.warn('No leaderboard found for pak', { modulePakId: this.modulePakId });
          }
        }).then(() => {
          this.$Loading.finish();
        });
    },

    getRowClass(row) {
      if (this.currentUser && this.currentUser.name === row.name && this.currentUser.ranking === row.ranking) {
        return 'current-user';
      }
      return '';
    },
  },
};
</script>

<template>
  <div id="login-container">
    <img class="kate-icon" :src="kateLogoImage" alt="Edukate Logo"/>
    <div v-if="token === undefined && !success" class="main-panel panel panel-default">
      <div v-if="withGitlabToken">
        <h2>Enter Gitlab Access Token</h2>
        <form @submit.prevent="signInWithGitlabToken" class="login-form">
          <label for="token-login">Token</label>
          <input id="token-login" v-model="glToken" class="login-token-input" :disabled="submitInProgress">
          <button class="btn btn-primary login-submit-button" :disabled="disableGlTokenSubmit">Login with token</button>
        </form>
      </div>
      <div v-else-if="passwordMode">
        <h2>Sign in</h2>
        <form @submit.prevent="signInWithPassword" class="login-form">
          <label for="email-pwd">Email Address</label>
          <input id="email-pwd" class="login-email-input" type="email" v-model="email" :disabled="submitInProgress">
          <label for="password">Password</label>
          <input id="password" class="login-password-input" type="password" v-model="password" :disabled="submitInProgress">
          <button class="btn btn-primary login-submit-button" :disabled="disableSubmit">Sign in</button>
          <button class="btn-link sign-in-mode-toggle" aria-label="Paswordless sign in" title="Paswordless sign in" @click="toggleMode">Or use passwordless sign in</button>
        </form>
      </div>
      <div v-else>
        <h2 v-if="forcedSignOut">You have been signed out</h2>
        <h2 v-else>Enter email to receive a sign in link</h2>
        <form @submit.prevent="requestSignInLink" class="login-form">
          <label for="email-signin">Email Address</label>
          <input id="email-signin" class="login-email-input" type="email" v-model="email" :disabled="submitInProgress">
          <button class="btn btn-primary login-submit-button" :disabled="disableSubmit">Send link</button>
          <button class="btn-link sign-in-mode-toggle" aria-label="Sign in with email and password" title="Sign in with email and password" @click="toggleMode">Or sign in with email and password</button>
        </form>
      </div>
    </div>
    <div v-else-if="success" class="success-container main-panel panel panel-default">
      <h2>Email has been sent</h2>
      <div class="notice">
        <span class="email-sent">Email has been sent to <strong>{{email}}</strong></span>
        <p><b>Please check your inbox for your sign in link</b></p>
        <p>
          If you did not receive the email:
          <ul>
            <li>Check your spam folder</li>
            <li>Check the above email is the one linked to your KATE account</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sign-in-mode-toggle {
  margin-top: 2em;
  text-align: center;
}

#login-container {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  text-align: center;
  display: block !important;
}

/* Text */
.kate-icon {
  width: 350px;
  margin-bottom: 50px;
}

#login-container .notice,
#login-container h1,
#login-container div h2 {
  color: var(--kate-type-light);
}

#login-container h1 {
  margin: 50px 0;
  transition: all 0.5s;
  text-align: center !important;
}

#login-container h1 b {
  color: var(--kate-primary);
}

#login-container .notice {
  text-align: left;
  font-size: 18px;
  padding: 50px;
}

.email-sent {
  margin: 0 0 20px;
  display: block;
}

.email-sent strong {
  color: var(--kate-secondary);
}

/* Panel */
.main-panel {
  width: 550px;
  max-width: 90vw;
  margin: 0 auto;
}

#login-container div h2 {
  margin: 0;
  background-color: var(--kate-panel-alt);
  font-family: hk-grotesk-light, sans-serif;
  padding: 25px 0;
  position: relative;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 2px solid var(--kate-background-alt);
}

.success-container {
  width: 50em !important;
}

/* Login form */
#login-container form {
  padding: 45px;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#login-container form label {
  display: block;
  color: var(--kate-type-light);
  font-weight: normal;
  text-align: left;
}

#login-container form input {
  margin-bottom: 25px;
  width: 100%;
  padding: 8px 15px;
  border: var(--input-border);
  background-color: var(--input-background);
  color: var(--kate-type-light);
  transition: all 0.3s;
  border-radius: 4px;
}

#login-container form input:focus {
  outline: 0;
  border: var(--input-border-focus);
}

#login-container form input[type="email"] {
  color: var(--kate-type-light);
}

/* Submit Button */
.login-submit-button {
  margin: 0 auto;
  text-align: center;
  padding: 1em 2em;
  font-size: 1rem;
}

form button:disabled:active {
  box-shadow: unset;
}

@media only screen and (max-width: 480px) {
  #login-container {
    top: 5%;
  }

  #login-container h1 {
    margin: 10px 0;
    font-size: 25px;
  }

  #login-container div h2 {
    padding: 15px 0;
  }

  #login-container div h2::before {
    top: 35px;
  }

  .kate-icon {
    margin-bottom: 20px;
  }
}

/* Loading Programmes */

.welcome-login {
  margin: 50px 0;
}

.welcome-login .spinner img {
  width: 180px;
}

.welcome-login .spinner {
  font-size: 75px;
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
}

.welcome-login p {
  font-family: "apercu_monoregular", monospace;
  font-size: 0.8em;
  margin-top: 20px;
}

@media (max-width: 767px) {
  #login-container {
    position: relative;
  }
}

@media (max-height: 680px) {
  #login-container {
    position: relative !important;
    background-image: none !important;
  }
}

</style>

<script>
import useGlobalStore from '../stores/global';
import useThemeStore from '../stores/theme';
import { EMAIL_REGEX } from '../constants';
import ErrorMixin from '../mixins/error-mixins';
import LoginMixin from '../mixins/login-mixin';
import { importImage } from '../modules/dynamic-image-importer';
import getOrNull from '../modules/get-or-null';

export default {
  props: {
    withGitlabToken: {
      type: Boolean,
      default: false,
    },
    buttons: {
      default: false,
      type: Boolean,
    },
  },

  mixins: [ErrorMixin, LoginMixin],

  data() {
    return {
      store: useGlobalStore(),
      themeStore: useThemeStore(),
      email: '',
      preferredName: '',
      password: '',
      fullName: '',
      glToken: '',
      success: false,
      tokenRequested: false,
      submitInProgress: false,
      passwordMode: false,
    };
  },

  beforeMount() {
    if (this.token) {
      this.validateToken();
    } else {
      this.$Loading.finish();
      if (this.isLoggedIn) {
        this.goHome();
      } else {
        this.$logger.pageReady();
      }
    }
  },

  computed: {
    kateLogoImage() {
      if (this.themeStore.theme !== 'light') {
        return importImage('kate_full_logo.svg');
      }
      return importImage('kate_full_logo_dark.svg');
    },

    disableSubmit() {
      return this.submitInProgress || !this.validEmail;
    },

    disableGlTokenSubmit() {
      return this.submitInProgress || !this.glToken;
    },

    validEmail() {
      const emailTest = RegExp(EMAIL_REGEX, 'g');
      return emailTest.test(this.email);
    },
    token() {
      return this.$route.query.token;
    },
    forcedSignOut() {
      return this.$route.query.signedOut;
    },
    route() {
      return this.$route.path;
    },
    isLoggedIn() {
      return this.store.isLoggedIn;
    },
  },

  methods: {
    toggleMode() {
      this.passwordMode = !this.passwordMode;
    },
    signInWithPassword() {
      this.$logger.info('Validating password', undefined, true);
      this.submitInProgress = true;
      this.$Loading.start();
      this.$http.post(`/api/auth/${this.store.appName}/token/login_password`, {
        email: this.email,
        password: this.password,
      }).then(() => {
        this.$logger.info('Logged in with password');
        this.logIn();
      }).catch(error => {
        this.$logger.warn('Error validating password', undefined, error);
        this.showError(error, false, 5000);
        // Need it here cos
        // we want to loading to continue UNTIL the redirect
        // page is finished loading
        // but if there's an error end here
        this.$Loading.finish();
      }).then(() => {
        this.submitInProgress = false;
      });
    },
    signInWithGitlabToken() {
      this.$logger.info('Validating gitlab token', undefined, true);
      this.submitInProgress = true;
      this.$Loading.start();
      this.$http.post(`/api/auth/${this.store.appName}/token/login_token`, {
        token: this.glToken,
      }).then(() => {
        this.$logger.info('Validated gitlab token');
        this.logIn();
      }).catch(error => {
        this.$logger.warn('Error validating user via gitlab', undefined, error);
        this.showError(error, true);
      }).then(() => {
        this.$Loading.finish();
        this.submitInProgress = false;
      });
    },
    validateToken() {
      if (this.token === undefined) {
        return;
      }
      this.$logger.info('Validating jwt token', undefined, true);
      this.$httpCancel();
      this.$http.post(`/api/auth/${this.store.appName}/token/login`, {}, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(() => {
        this.$logger.info('Validated jwt token');
        this.store.logOut();
        this.logIn();
      }).catch(err => {
        this.$logger.warn('Error validating jwt token', undefined, err);
        this.showError('Invalid or expired token');
        this.$router.replace({ query: {} });
        this.$logger.pageReady();
      });
    },
    requestSignInLink() {
      this.$logger.info('Requesting sign in link', undefined, true);
      this.$Loading.start();
      this.submitInProgress = true;
      const loginEndpoint = `/api/auth/${this.store.appName}/send_email/sign_in`;
      this.$http.post(
        loginEndpoint,
        { email: this.email },
      ).then(() => {
        this.$logger.info('Sign in link request approved', undefined, true);
        this.success = true;
        this.$ktoast.success('Email sent');
      }).catch(error => {
        this.$logger.warn('Error requesting sign in link', undefined, error);
        if (getOrNull('response.status', error) === 429) {
          this.$ktoast.warning('Too many requests, please try again later');
        } else {
          this.$ktoast.success('Email sent');
        }
      }).then(() => {
        this.submitInProgress = false;
        this.$Loading.finish();
      });
    },
  },
};
</script>

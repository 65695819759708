<template>
  <div>
    <!-- Content -->
    <div v-if="layout" id="feedback-container">
      <div class="row">
        <!-- LEFT COLUMN -->
        <div class="col-md-5">
          <div class="panel panel-default score-info">
            <!-- Overall Score -->
            <div class="overall-score">
              <k-progress :percentage="overallScore" shape="circle" size="medium" :precision="scorePrecision" ariaLabel="Assignment Score"></k-progress>
              <div class="well well-lg">
                <div class="well-title">
                  <h2>Overall score</h2>
                  <span v-if="currentTimestamp" class="k-timestamp">{{parseTimestamp(currentTimestamp)}}</span>
                </div>
              </div>
            </div>
            <div class="progress-indicator full-width-container">
              <k-stepper
              :steps="stages"
              v-model="currentStage"
              direction="horizontal"
              :clickable="false"
              :enable-active="isExtraPoints"
              ></k-stepper>
            </div>
            <div v-if="showPercentile" class="user-ranking">
              <k-ranking
                :percentile="percentile"
              ></k-ranking>
            </div>
          </div>
          <div v-if="readings && readings.length > 0" class="panel panel-default readings">
            <div class="summary-panel k-feedback-layout-block">
              <div class="panel-body full-width">
                <reading-block
                  :reading-list="readings"
                  :pak-key="projectMeta.key"
                  :submission-id="submissionId"
                  :timestamp="currentTimestamp"
                  @rated="(rating) => $emit('rated', rating)"
                >
                </reading-block>
              </div>
            </div>
          </div>
          <div v-if="!markerErrorsResult" class="panel panel-default all-files">
            <!-- All Files -->
            <div class="row summary-panel k-feedback-layout-block">
              <div class="panel-body full-width">
                <h2 class="feedback-panel-title">
                  <i class="fas fa-code"></i> All Files
                </h2>
                <feedback-layout
                  @select="updateFilters"
                  :layout="layout"
                  :syntax-errors="syntaxErrorLocation"
                  :file-level-errors="fileLevelErrors"
                  :timeout-error="timeoutError"
                  :memory-error="memoryError"
                  ></feedback-layout>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <!-- Feedback details -->
          <div class="row summary-panel k-feedback-test-block" v-if="mainResultBlock.length">
            <feedback-block v-if="evaluationResult !== undefined || !isOnlyExtraPoints"
              @clearfilter="clearFilter"
              icon-class="fas fa-code"
              :title="feedbackBlockTitle"
              :notebook="notebook"
              :result-entries="mainResultBlock"
              :filePath="currentFilePath"
              :layout-object="layout"
              :functionTestedName="currentFunctionName"
              :ignore-success="true"
              :display-scoring-indicator="true"
              :sort-priority="fileFunctionSortOrder"
              :submission-id="submissionId"
            ></feedback-block>
          </div>

          <!-- Extra Points -->
          <extra-points v-if="extraPointsSuccess"
            :extra-points-results="extraPointsSuccess"
            :precision="scorePrecision" >
          </extra-points>

          <!-- Informative -->
          <info-block v-if="informativeSuccess"
            :info-results="informativeSuccess">
          </info-block>

          <!-- Code Quality -->
          <div v-if="qualityResult" class="row summary-panel k-feedback-quality-block">
            <feedback-block
              @clearfilter="clearFilter"
              icon-class="fas fa-check-circle"
              title="Code Quality"
              :result-entries="qualityResult.results"
              :filePath="currentFilePath"
              :display-runner-keys="true"
              :display-description="true"
              :display-scoring-indicator="false"
              :submission-id="submissionId"
            ></feedback-block>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoadingFeedback"></div>
    <div v-else class="empty-placeholder-container">
      <empty-placeholder v-if="isDashboard" info="The student has not made a submission yet"></empty-placeholder>
      <empty-placeholder v-else></empty-placeholder>
    </div>
  </div>
</template>

<style>
.k-feedback-quality-block .feedback-block-content .feedback-evaluation .feedback-content {
  min-height: auto;
}

.progress-indicator {
  text-align: center;
}

.k-stepper-circle i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

</style>

<style scoped>
.panel-default {
  padding: 10px;
}

.score-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto;
}

.summary-list button span.errors {
  padding: 5px 15px;
  color: var(--kate-type-light) !important;
  background-color: var(--kate-danger);
  border-radius: 4px;
}

.summary-list button span.failures {
  padding: 5px 15px;
  color: var(--kate-type-dark) !important;
  background-color: var(--kate-warning);
  border-radius: 4px;
}

.row.summary-panel.k-feedback-test-block {
  margin: 0;
}

.readings .panel-body,
.all-files .panel-body {
  padding: 0;
}

.panel-body {
  width: 100%;
}

.panel-body ul {
  padding: 0;
}

.panel-body ul li {
  list-style: none;
}

h2.evaluation-title {
  padding: 15px;
  width: 100%;
}

/* ==== Current ==== */
.well-title {
  margin: 3px;
}

.well-title .k-timestamp {
  color: var(--kate-type-primary);
  font-size: 0.9em;
}

.empty-placeholder-container {
  position: relative;
  padding: 60px 0;
}

.k-feedback-code-block {
  display: block !important;
}

.k-feedback-actual .col-md-12 {
  margin: 0 !important;
}
</style>

<script>
import useGlobalStore from '../stores/global';
import KProgress from '../components/k-progress.vue';
import FeedbackBlock from './feedback-block.vue';
import TimeMixins from '../mixins/time-mixins';
import RepoControl from '../mixins/repo-control-mixins';
import { FEEDBACK_PARAMS } from '../constants';
import ReadingBlock from './readings/reading-block.vue';
import EmptyPlaceholder from '../components/empty-placeholder.vue';
import FeedbackLayout from './feedback-layout.vue';
import ExtraPointsBlock from './extra-points-block.vue';
import InfoBlock from './info-results-block.vue';
import KStepper from '../components/k-stepper.vue';
import KRanking from '../components/k-ranking.vue';
import getOrNull from '../modules/get-or-null';

/* Steps */
const VALIDATION_STAGE = 0;
const EVALUATION_STAGE = 1;
const EXTRA_POINTS_STAGE = 2;
const COMPLETE_STAGE = 3;

const DEFAULT_PRECISION = 4;

export default {
  props: {
    allowSubmission: {
      type: Boolean,
      default: true,
    },
    layout: Object,
    overallScore: Number,
    currentTimestamp: String,
    commit: String,
    supportButton: {
      type: Boolean,
      default: false,
    },
    // Only need this if its a notebook submission
    notebook: {
      type: Object,
    },
    validationResult: Object,
    evaluationResult: Object,
    extraPointResult: Object,
    qualityResult: Object,
    informativeResult: Object,
    markerErrorsResult: Array,
    sha: {
      type: String,
      default: 'master',
    },
    projectMeta: Object,
    readings: Array,
    submissionId: {
      type: Number,
    },
    isLoadingFeedback: {
      type: Boolean,
      default: false,
    },
    percentile: {
      type: Number,
    },
  },

  mixins: [TimeMixins, RepoControl],

  watch: {
    layout() {
      this.currentFilePath = undefined;
    },
    // Watch the currentTimeStamp and when it change, re-getCurrentStage
    currentTimestamp() {
      this.currentStage = this.getCurrentStage();
      this.currentFilePath = undefined;
      this.currentFunctionName = undefined;
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      id: null,
      currentFilePath: undefined,
      currentFunctionName: undefined,
      currentStage: 0,
    };
  },
  components: {
    'k-progress': KProgress,
    'feedback-block': FeedbackBlock,
    'reading-block': ReadingBlock,
    'empty-placeholder': EmptyPlaceholder,
    'feedback-layout': FeedbackLayout,
    'extra-points': ExtraPointsBlock,
    'info-block': InfoBlock,
    'k-stepper': KStepper,
    'k-ranking': KRanking,
  },
  mounted() {
    this.id = this.$.uid;
    this.currentStage = this.getCurrentStage();
  },
  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },
    showPercentile() {
      return getOrNull('additional_features.show_percentiles', this.projectMeta) || false;
    },
    scorePrecision() {
      return getOrNull('additional_features.number_precision', this.projectMeta) || DEFAULT_PRECISION;
    },
    feedbackBlockTitle() {
      if (this.extraPointsSuccess !== undefined && this.evaluationResult === undefined) {
        // Just extra points
        return 'Extra Points';
      }
      if (!this.evaluationResult) {
        // No evaluation result as it did not pass validation
        return 'Validation';
      }
      return 'Evaluation';
    },
    topLevelErrors() {
      if (!this.evaluationResult) {
        return [];
      }
      return this.evaluationResult.results.filter(x => !x.file_path && !(x.function_tested__name || x.value_tested));
    },
    fileLevelErrors() {
      if (this.syntaxErrorLocation) {
        return this.syntaxErrorLocation;
      }
      if (!this.evaluationResult || !this.evaluationResult.results || this.evaluationResult.passed) {
        return undefined;
      }
      if (this.notebookOnly) {
        if (this.overallScore > 0) {
          // Can't be a _real_ file error in a notebook if the score doesn't go to zero
          return undefined;
        }
        return [...new Set(
          this.evaluationResult.results.filter(x => this.checkNotebookResultIsFileError(x)).map(x => x.file_path),
        )];
      }
      return [...new Set(
        this.evaluationResult.results.filter(x => this.checkResultIsFileError(x)).map(x => x.file_path),
      )];
    },
    fileFunctionSortOrder() {
      // Returns a sorting priority for feedback blocks
      // Just returns an array of [file-functions] strings from layout
      if (!this.layout) {
        return undefined;
      }
      return Object.entries(this.layout).reduce((acc, entry) => acc
        .concat(entry[1].functions
          .map(x => `${entry[0]}:${x.function_name}`)), []);
    },
    timeoutError() {
      if (!this.evaluationResult) {
        return false;
      }
      return !this.evaluationResult.passed && this.evaluationResult.results[0].type === 'timeout';
    },
    memoryError() {
      if (!this.evaluationResult) {
        return false;
      }
      return !this.evaluationResult.passed && this.evaluationResult.results[0].type === 'memory';
    },
    syntaxErrorLocation() {
      if (!this.validationResult || this.validationResult.passed) {
        return undefined;
      }
      return this.validationResult.results
        .filter(x => x.result_type === 'error' || x.result_type === 'failure')
        .map(x => x.file_path);
    },
    extraPointsSuccess() {
      if (!this.extraPointResult) {
        return undefined;
      }
      return this.extraPointResult.results.filter(x => x.result_type === 'success');
    },
    informativeSuccess() {
      if (!this.informativeResult) {
        return undefined;
      }
      return this.informativeResult.results.filter(x => x.result_type === 'success');
    },
    mainResultBlock() {
      let output = [];
      function setResultErrorType(element) {
        const newElement = element;
        newElement.result_type = 'error';
        return newElement;
      }
      if (this.markerErrorsResult) {
        output = output.concat(this.markerErrorsResult.map(x => setResultErrorType(x)));
      }
      if (this.validationResult) {
        output = output.concat(this.validationResult.results.filter(
          x => x.result_type !== 'success',
        ));
      }
      if (this.evaluationResult) {
        output = output.concat(this.evaluationResult.results);
      }
      if (this.extraPointResult) {
        output = output.concat(this.extraPointResult.results.filter(x => x.result_type !== 'success'));
      }
      if (this.informativeResult) {
        output = output.concat(this.informativeResult.results.filter(x => x.result_type !== 'success'));
      }
      return output;
    },
    pakType() {
      return this.$route.params.pakType;
    },
    showStyle() {
      if (this.currentFile) {
        return Boolean(this.currentFile.pylint);
      }
      return false;
    },
    projectName() {
      return this.$route.params.projectName;
    },
    metrics() {
      return Object.keys(FEEDBACK_PARAMS);
    },
    notebookOnly() {
      if (!this.projectMeta) {
        return undefined;
      }
      return Boolean(this.projectMeta.notebook_only);
    },
    /* Progress Indicator */
    validationStage() {
      return {
        icon: this.getIcon(VALIDATION_STAGE),
        name: 'Validation',
        tooltip: 'Checks that your code can be properly loaded (no syntax errors, not using any restricted libraries, etc...).',
      };
    },
    evaluationStage() {
      return {
        icon: this.getIcon(EVALUATION_STAGE),
        name: 'Evaluation',
        // tooltip: this.evaluationTooltip,
        tooltip: 'Runs a series of pass/fail tests against your code',
      };
    },
    extraPointsStage() {
      return {
        icon: this.getIcon(EXTRA_POINTS_STAGE),
        name: 'Extra Points',
        tooltip: 'Computes additional metrics against your code to grant extra points. In projects with extra points, you can keep improving your code to get a better score, but it is usually not possible to reach 100%.',
      };
    },
    stages() {
      const output = [this.validationStage];
      if (this.evaluationResult !== undefined) {
        output.push(this.evaluationStage);
      }
      const jobs = getOrNull('runners.jobs', this.projectMeta) || [];
      if (jobs.indexOf('extra_points') !== -1 || jobs.indexOf('quality') !== -1) {
        output.push(this.extraPointsStage);
      }
      return output;
    },
    isExtraPoints() {
      return this.currentStage === EXTRA_POINTS_STAGE;
    },
    isOnlyExtraPoints() {
      // In future we should get this from the runner jobs
      if ((this.evaluationResult === undefined && this.extraPointResult && !this.extraPointResult.passed) || !this.validationResult.passed) {
        return false;
      }
      return true;
    },
  },

  methods: {
    clearFilter() {
      this.$logger.info('Clearing filter', { modulePakId: this.projectMeta.id }, true);
      this.currentFilePath = undefined;
      this.currentFunctionName = undefined;
    },
    updateFilters(filename, functionName) {
      this.$logger.info('Updating filter', {
        modulePakId: this.projectMeta.id,
        filename,
        functionName,
      });
      this.currentFilePath = filename;
      this.currentFunctionName = functionName;
    },
    getFirstFile() {
      return Object.keys(this.layout)[0];
    },
    checkResultIsTimeout(res) {
      if (res.details) {
        return res.details.slice(0, 16) === 'TestTimeoutError' || res.details.slice(0, 12) === 'TimeoutError';
      }
      return false;
    },
    checkResultIsFileError(res) {
      // NB: only valid for evaluation results
      // If the result has no value_tested and no function_tested__name then
      // it must've have been raised during execution of the script and is therefore
      // a file level error which causes all tests on that file to fail to load
      return !(res.value_tested || res.function_tested__name);
    },
    checkNotebookResultIsFileError(res) {
      // NB: only valid for evaluation results
      const isError = res.result_type === 'error';
      const notImplemented = res.details === 'Not Implemented';
      const testTimeout = this.checkResultIsTimeout(res);
      // TestTimeout and NotImplemented errors are not counted as file level errors
      if (!(res.value_tested || res.function_tested__name) || (isError && !notImplemented && !testTimeout)) {
        return true;
      }
      return false;
    },
    /* Progress indicator */
    getCurrentStage() {
      if (this.currentTimestamp === undefined || !this.validationResult) {
        return VALIDATION_STAGE;
      }
      if (this.overallScore === 100) {
        return COMPLETE_STAGE;
      }
      let stage = 0;
      const stages = [this.validationResult];
      if (this.evaluationResult) {
        stages.push(this.evaluationResult);
      }
      for (let i = 0; i < stages.length; i++) {
        if (!stages[i].passed) {
          break;
        }
        stage += 1;
      }
      return stage;
    },
    getIcon(stageNumber) {
      if (this.currentStage > stageNumber) {
        return 'fas fa-check';
      }
      if (this.currentStage === EXTRA_POINTS_STAGE) {
        return 'fas fa-plus';
      }
      if (this.isOnlyExtraPoints) {
        return 'fas fa-plus';
      }
      return 'fas fa-times';
    },
  },
};
</script>

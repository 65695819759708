<template>
  <div>
    <div v-if="started && projectMeta && !expired && !competitionFinished && !competitionMode" class="control-btn">
      <button class="clickable btn btn-danger" @click="resetPak">
        Reset
      </button>
    </div>

    <div v-if="started && projectMeta && projectMeta.notebook_only && !expired && !competitionFinished" class="control-btn">
      <button class="clickable btn btn-success" @click="submitNotebook">
        <i class="fas fa-arrow-up"></i>
        Submit
      </button>
    </div>

    <div v-if="started && projectMeta.resource_link" class="control-btn">
      <a class="btn btn-primary" :href="`/api/curriculum/pak/${projectMeta.id}/zip`">
        <i class="fas fa-download"></i>
        Resources
      </a>
    </div>

    <div v-if="started && !competitionFinished" class="control-btn">
      <open-kloud-button v-if="showKloud" :asset-id="projectMeta.id" asset-type="module_pak" @open="handleKodRedirect"></open-kloud-button>
      <div class="clone-container" v-if="!projectMeta || !projectMeta.notebook_only" ref="cloneDropdown">
        <button @click="isOpen = !isOpen" class="control-btn-content btn-primary">
          <i class="far fa-copy"></i>
          Clone <i class="caret"></i>
        </button>
        <div :class="{isOpen}" class="dropdown">
          <div class="clone-types">
            <form>
              <label for="clone-https-input">Clone using HTTPS</label>
              <input id="clone-https-input" type="text" :value="getHttpUrl(projectMeta.http_url_to_repo)" readonly>
              <k-tooltip :text="active != 2 ? 'Copy to Clipboard' : 'Copied'">
                <button type="button" class="btn primary"
                  @click="copyHttp(projectMeta.http_url_to_repo), active = 2"
                  title="Clone using HTTPS" aria-label="Clone using HTTPS">
                  <i class="fas fa-clone"></i>
                </button>
              </k-tooltip>
            </form>
            <form>
              <label for="clone-ssh-input">Clone using SSH</label>
              <input id="clone-ssh-input" type="text" :value="projectMeta.ssh_url_to_repo" readonly>
              <k-tooltip :text="active != 1 ? 'Copy to Clipboard' : 'Copied'">
                <button type="button" class="btn primary"
                  @click="copySsh(projectMeta.ssh_url_to_repo), active = 1"
                  title="Clone using SSH" aria-label="Clone using SSH">
                  <i class="fas fa-clone"></i>
                </button>
              </k-tooltip>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!started" class="start-project-container">
      <start-project @fork="startProject"></start-project>
    </div>
  </div>
</template>

<style scoped>
.header-dropdown > div {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* Control buttons */
.control-btn .clone-container {
  position: relative;
}

.control-btn .control-btn-content {
  font-size: 0.88em;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  gap: 8px;
  width: 100%;
}

.control-btn .control-btn-content.danger {
  background-color: var(--kate-danger-dark);
}

.control-btn .control-btn-content.danger:hover {
  background-color: var(--kate-danger-light);
}

.control-btn .control-btn-content.kloud-btn {
  white-space: normal;
}

.control-btn .clone-container a:hover,
.control-btn .kloud-launch-button a:hover {
  text-decoration: none;
}

.kloud-launch-button,
.dropdown a {
  position: relative;
}

/* Clone Container */
.clone-container .dropdown {
  background-color: var(--kate-background);
  padding: 15px;
}

.clone-container label {
  color: var(--kate-type-light);
  width: 100%;
}

.clone-container input[type="text"] {
  color: var(--kate-type-primary);
  border: var(--input-border-alt);
  padding: 5px;
  flex: 1;
  z-index: 1;
}

.clone-container a button {
  border-radius: 0;
}

.clone-types form {
  display: flex;
  flex-wrap: wrap;
}

.clone-types form:last-child {
  margin-top: 10px;
}

.clone-types form a,
.clone-types form button {
  box-shadow: none !important;
  border-radius: 0;
}

/* Dropdown */

.control-btn .dropdown {
  width: 350px;
  position: absolute;
  top: 50px;
  right: 0;
  display: block;
  opacity: 0;
  transform: translateY(0%);
  transition: all 0.2s ease-in-out;
  z-index: -1;
}

.control-btn .dropdown.isOpen {
  opacity: 1;
  top: 50px;
  z-index: 10;
  display: block;
  box-shadow: var(--box-shadow);
}

/* dropdown container arrow pointing up */
.control-btn .dropdown .clone-types::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -6px;
  right: 40px;
  margin-top: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent var(--kate-background);
}

@media (max-width: 1024px) {
  .control-btn .dropdown {
    width: 250px;
    top: 50px;
    right: 0;
    left: 10px;
  }

  .control-btn .dropdown .clone-types::before {
    right: 0;
    left: 10px;
  }
}

@media (max-width: 767px) {
  .control-btn .dropdown.isOpen {
    right: unset;
    left: 0;
  }

  .control-btn .dropdown {
    width: 250px;
    top: 50px;
    right: 0;
    left: 10px;
  }

  .control-btn .dropdown .clone-types::before {
    right: 0;
    left: 10px;
  }
}

@media (max-width: 658px) {
  .control-btn .dropdown {
    right: 12%;
  }
}
</style>
<script>
import RepoControl from '../mixins/repo-control-mixins';
import KTooltip from '../components/k-tooltip.vue';
import KStartProject from './k-start-project.vue';
import OpenKloudButton from '../components/open-kloud-button.vue';

export default {
  mixins: [RepoControl],

  props: {
    projectMeta: {
      type: Object,
      required: true,
    },
    started: {
      type: Boolean,
      default: false,
    },
    expired: {
      type: Boolean,
      default: false,
    },
    kloudEnabled: {
      type: Boolean,
    },
    competitionFinished: {
      type: Boolean,
    },
    competitionMode: {
      type: Boolean,
    },
  },

  data() {
    return {
      isOpen: false,
      active: false,
    };
  },

  components: {
    KTooltip,
    OpenKloudButton,
    'start-project': KStartProject,
  },

  beforeMount() {
    this.getRepoAccessToken();
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    showKloud() {
      return (this.kloudEnabled && this.projectMeta && this.projectMeta.kloud_compatible && this.projectMeta.notebook_only);
    },
    encodedResourceLink() {
      if (this.projectMeta && this.projectMeta.resource_link) {
        return encodeURIComponent(this.projectMeta.resource_link);
      }
      return undefined;
    },
  },

  methods: {
    resetPak() {
      this.$emit('pakReset');
    },
    submitNotebook() {
      this.$emit('notebookUpload');
    },
    startProject() {
      this.$emit('fork');
    },

    handleClickOutside(event) {
      const cloneDropdown = this.$refs.cloneDropdown;
      if (cloneDropdown && !cloneDropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    handleKodRedirect() {
      this.$router.push({ name: 'pak_ov_instructions', query: { load_kod: 'true' } });
    },
  },
};
</script>

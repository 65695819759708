<template>
  <div id="error-container">
    <svg width="380px" height="500px" viewBox="0 0 837 1045" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z" id="polygon-404-1" stroke="var(--kate-logo-blue)" stroke-width="18" sketch:type="MSShapeGroup"/>
        <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z" id="polygon-404-2" stroke="var(--kate-logo-purple)" stroke-width="12" sketch:type="MSShapeGroup"/>
        <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z" id="polygon-404-3" stroke="var(--kate-logo-magenta)" stroke-width="8" sketch:type="MSShapeGroup"/>
        <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z" id="polygon-404-4" stroke="var(--kate-logo-orange)" stroke-width="12" sketch:type="MSShapeGroup"/>
        <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z" id="polygon-404-5" stroke="var(--kate-logo-lime-green)" stroke-width="12" sketch:type="MSShapeGroup"/>
        <path d="M500,875 L575,925 L575,1005 L500,1050 L425,1005 L425,925 L500,875 Z" id="polygon-404-6" stroke="var(--kate-logo-green)" stroke-width="12" sketch:type="MSShapeGroup"/>
      </g>
    </svg>
    <div class="message-box">
      <slot name="code"></slot>
      <slot name="error-message"></slot>
      <div class="action-link-wrap">
        <router-link class="btn btn-primary" :to="homeRoute">Go back</router-link>
      </div>
    </div>
  </div>
</template>

<style>
#error-container {
  font-family: hk-grotesk-light, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 232px);
}

.message-box {
  margin-left: 50px;
  color: var(--kate-type-light);
}

.message-box h1 {
  font-size: 10em;
  font-family: "apercu_monoregular", sans-serif;
  font-weight: bold;
}

.message-box p {
  max-width: 400px;
}

.action-link-wrap {
  margin-top: 40px;
}

.action-link-wrap a {
  padding: 8px 25px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}

#polygon-404-1,
#polygon-404-2,
#polygon-404-3,
#polygon-404-4,
#polygon-404-5,
#polygon-404-6 {
  animation: float 1s infinite ease-in-out alternate;
}

#polygon-404-2 {
  animation-delay: 0.2s;
}

#polygon-404-3 {
  animation-delay: 0.4s;
}

#polygon-404-4 {
  animation-delay: 0.6s;
}

#polygon-404-5 {
  animation-delay: 0.8s;
}

#polygon-404-6 {
  animation-delay: 1s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 767px) {
  #not-found-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    height: 100%;
  }

  .message-box {
    margin-left: 0;
    text-align: center;
  }

  #error-container svg {
    height: 100%;
    width: 150px;
    margin-top: 15px;
  }

  .action-link-wrap {
    text-align: center;
  }
}
</style>

<script>
import useGlobalStore from '../stores/global';

export default {
  data() {
    return {
      store: useGlobalStore(),
    };
  },

  mounted() {
    this.$Loading.finish();
    this.$logger.pageReady();
  },

  computed: {
    isDashboard() {
      return this.store.appName === 'dashboard';
    },
    homeRoute() {
      return { name: this.isDashboard ? 'dashboard_home' : 'programme_page' };
    },
  },
};
</script>

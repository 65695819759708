import useGlobalStore from '../stores/global';
import getOrNull from '../modules/get-or-null';
import PakEndpointMixin from './pak-endpoint-mixin';

export default {
  mixins: [PakEndpointMixin],
  data() {
    return {
      store: useGlobalStore(),
      currentNotebook: null,
      notebookReady: false,
    };
  },
  computed: {
    notebookPath() {
      if (!this.projectMeta || !this.projectMeta.notebook_only) {
        return undefined;
      }
      return Object.keys(this.projectMeta.layout)[0];
    },
    notebookFilename() {
      if (!this.notebookPath) {
        return undefined;
      }
      return this.notebookPath.split('/').pop();
    },
  },
  methods: {
    showSubmissionPopup() {
      this.$ktoast.success('Submitted');
    },
    loadNotebookFromFileList(files) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = ev => {
          this.currentNotebook = JSON.parse(ev.target.result);
          resolve(this.uploadCurrentNotebook());
        };
        reader.readAsText(files[0]);
      });
    },
    uploadCurrentNotebook() {
      this.$logger.info('Uploading notebook for pak', { modulePakId: this.projectMeta.id }, true);
      // TODO handle multiple notebooks per repo
      // js was a mistakte
      const changes = {};
      changes[this.notebookPath] = JSON.stringify(
        this.removeOutputsFromNotebook(this.currentNotebook),
      );
      const now = new Date();
      return this.$http.post(`/api/curriculum/pak/${this.projectMeta.id}/submit`, {
        commit_message: `Updated at ${now.toISOString()}`,
        changes,
      }).then(() => { // on success
        this.showSubmissionPopup();
        this.store.notebookReadyToggle();
        this.$logger.info('Uploaded notebook for pak', { modulePakId: this.projectMeta.id }, true);
      }).catch(err => { // on error
        this.$logger.error('Error uploading for pak', { modulePakId: this.projectMeta.id }, err);
        this.showError(err, true);
      });
    },
    downloadCurrentNotebookAsIpynb(notebook, filename) {
      this.downloadStringAsFile(JSON.stringify(notebook), filename, 'application/x-ipynb+json');
    },
    downloadNotebook() {
      this.$logger.info('Getting notebook for pak', { modulePakId: this.projectMeta.id }, true);
      return this.$http.get(`${this.pakDownloadEndpoint}/file_content?path=${encodeURIComponent(this.notebookPath)}`).then(response => {
        this.currentNotebook = JSON.parse(response.data.file_content);
        this.$logger.info('Got notebook for pak', { modulePakId: this.projectMeta.id });
      }).catch(err => {
        if (this.$http.errIn(err, [404]) && getOrNull('.response.data.err', err) === 'solution_not_released') {
          this.$logger.warn('Attempted to access unreleased solution - route to 404', { modulePakId: this.projectMeta.id }, err);
        } else {
          this.$logger.error('Error getting notebook for pak', { modulePakId: this.projectMeta.id }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.notebookReady = true;
      });
    },
    removeOutputsFromNotebook(notebookOriginal) {
      // Hack
      // cloud tasks max size is 100kb, images and large dataframes can exceed that
      // Need to find a better way to handle this

      // Simple deep clone
      const notebook = JSON.parse(JSON.stringify(notebookOriginal));
      for (let i = 0; i < notebook.cells.length; i++) {
        if (notebook.cells[i].cell_type === 'code' && notebook.cells[i].outputs !== undefined) {
          notebook.cells[i].outputs = []; // eslint-disable-line no-param-reassign
        }
      }
      return notebook;
    },
  },
};

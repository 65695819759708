import { plural } from 'pluralize';

export const GOOGLE_CLOUD_STORAGE = 'https://storage.googleapis.com/';

export const FEEDBACK_PARAMS = {
  // Options:
  //    [
  //      title,
  //      name, desc, message,
  //      rowTitlePrefix, rowSubtitlePrefix, errorboxPrefix,
  //      perfectMessage
  //    ]
  //    Currently accepted tabs: errors, failures, results
  file_score: {
    title: 'File Score',
  },
  complexity: {
    title: 'cyclomatic complexity (mccabe)',
    rowTitleKey: 'function',
    rowSubtitleKey: 'line',
    errorboxKey: 'error_message',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Line number: ',
    perfectMessage: 'Good McCabe Complexity!',
  },
  mlmetrics: {
    rowTitleKey: {
      errors: 'file',
      results: 'description',
    },
    rowSubtitleKey: {
      errors: 'description',
    },
    errorboxKey: {
      errors: 'traceback',
    },
    resultboxKey: {
      results: 'result',
    },
    rowTitlePrefix: { errors: 'File: ' },
    rowSubtitlePrefix: 'Test description: ',
    resultboxPrefix: 'Results:\n',
    title: 'Model Test',
  },
  pep8: {
    rowTitleKey: 'line',
    errorboxKey: 'message',
    rowTitlePrefix: 'Line number: ',
  },
  pylint: {
    rowTitleKey: 'function',
    rowSubtitleKey: 'line',
    errorboxKey: 'message',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Line number: ',
  },
  tests: {
    title: 'Tests',
    rowTitleKey: 'function',
    rowSubtitleKey: 'description',
    rowTitleDefault: '__main__',
    infoboxKey: 'full_description',
    errorboxKey: 'traceback',
    rowTitlePrefix: 'Function: ',
    rowSubtitlePrefix: 'Test description: ',
  },
};

export const FEEDBACK_RESULT_KEYS = [
  'validation_result',
  'evaluation_result',
  'extra_points_result',
  'quality_result',
  'informative_result',
];

export const METRICS_COLORS = {
  score: 'var(--kate-0)',
  file_score: 'var(--kate-0)',
  unittest: 'var(--kate-1)',
  pylint: 'var(--kate-2)',
  pep8: 'var(--kate-3)',
  complexity: 'var(--kate-4)',
  softmetrics: 'var(--kate-5)',
  mlmetrics: 'var(--kate-6)',
};

export const EMAIL_REGEX = /[^@]+@[^@]+\.[^@]+/;

export const ASSET_TYPES = {
  pak: {
    prettyName: 'Assignment',
    routeName: 'pak',
    apiRoot: 'pak',
    icon: 'fa-duotone fa-laptop-code',
    completion_type: 'submission',
    dataLossField: 'has_submissions',
    additionalReleaseFields: [
      {
        key: 'end_date',
        type: 'date',
        title: 'Deadline',
      },
      {
        key: 'time_limit',
        type: 'time',
        title: 'Time Limit',
      },
      {
        key: 'hide_error_traceback',
        type: 'boolean',
        title: 'Hide Error Traceback',
      },
      {
        key: 'show_leaderboard',
        type: 'boolean',
        title: 'Show Leaderboard',
      },
      {
        key: 'solution_released',
        type: 'boolean',
        title: 'Solution Released',
      },
    ],
  },
  learning_unit: {
    prettyName: 'Learning Unit',
    routeName: 'lu',
    apiRoot: 'learning_unit',
    icon: 'fa-duotone fa-graduation-cap',
    dataLossField: 'has_saves',
  },
  slide_deck: {
    prettyName: 'Slides',
    routeName: 'slides',
    apiRoot: 'slide_deck',
    icon: 'fa-duotone fa-clone',
  },
  calendar_event: {
    prettyName: 'Event',
    routeName: 'calendar_event',
    apiRoot: 'calendar_event',
    icon: 'fa-duotone fa-calendar-alt',
    additionalReleaseFields: [
      {
        key: 'event_start',
        type: 'datetime',
        title: 'Event Start',
      },
      {
        key: 'event_end',
        type: 'datetime',
        title: 'Event End',
      },
      {
        key: 'live_event_link',
        type: 'input-text',
        title: 'Live Event Link',
      },
      {
        key: 'location',
        type: 'input-text',
        title: 'Location',
      },
      {
        key: 'recording_link',
        type: 'multi-link-input',
        title: 'Recording Link',
      },
    ],
    hasTrainers: true,
    releaseWarningMessage:
      `On releasing or changing the start time of an event,
      reminder emails will be scheduled to be sent to all learners with access.
      This process will overwrite any currently scheduled emails for the event.`,
    releaseCallbackData: 'tasks',
  },
  meeting: {
    prettyName: 'Meeting',
    routeName: 'meeting',
    apiRoot: 'meeting',
    icon: 'fa-duotone fa-headset',
  },
  video: {
    prettyName: 'Video',
    routeName: 'video',
    apiRoot: 'video',
    icon: 'fa-duotone fa-film',
  },
  quiz: {
    prettyName: 'Quiz',
    routeName: 'quiz',
    apiRoot: 'quiz',
    icon: 'fa-duotone fa-lightbulb',
    completion_type: 'submission',
    dataLossField: 'has_submissions',
    additionalReleaseFields: [
      {
        key: 'deadline',
        type: 'date',
        title: 'Deadline',
      },
    ],
  },
  questionnaire: {
    prettyName: 'Questionnaire',
    routeName: 'questionnaire',
    apiRoot: 'questionnaire',
    icon: 'fa-duotone fa-ballot-check',
    completion_type: 'submission',
  },
  markdown_page: {
    prettyName: 'Page',
    routeName: 'markdown_page',
    apiRoot: 'markdown_page',
    icon: 'fa-duotone fa-file-lines',
  },
};

Object.keys(ASSET_TYPES).forEach(assetType => {
  ASSET_TYPES[assetType].defaultChapterTitle = plural(ASSET_TYPES[assetType].prettyName);
});

export const MODULE_TYPES = {
  module: {
    idField: 'moduleId',
    programmeIdField: 'programmeId',
    name: 'Module',
    parentName: 'Programme',
    moduleApiRoot: '/api/curriculum/admin/modules',
    programmeApiRoot: '/api/curriculum/programmes',
  },
  programme_blueprint_module: {
    idField: 'programmeBlueprintModuleId',
    programmeIdField: 'programmeBlueprintId',
    name: 'Programme Blueprint Module',
    parentName: 'Programme Blueprint',
    moduleApiRoot: '/api/curriculum/blueprints/programmes/modules',
    programmeApiRoot: '/api/curriculum/blueprints/programmes',
  },
  // FEATURE-TODO: Will eventually be deprecated
  module_blueprint: {
    idField: 'moduleBlueprintId',
    programmeIdField: null,
    name: 'Module Blueprint',
    parentName: null,
    moduleApiRoot: '/api/curriculum/blueprints/modules',
    programmeApiRoot: null,
  },
};

export const PROGRAMME_TYPES = {
  programme: {
    name: 'Programme',
    icon: 'fa-duotone fa-diagram-project',
    child: 'module',
  },
  programme_blueprint: {
    name: 'Programme Blueprint',
    icon: 'fa-duotone fa-compass-drafting',
    child: 'programme_blueprint_module',
  },
};

<template>
  <div v-if="moduleDetails && initialLoadFinished">
    <div class="sidebar-header">
      <div class="sidebar-title">
        <router-link :class="{'icon-only': sidebarOpen} " :to="{ name: 'programme_page', params: { programmeId: this.moduleDetails.programme_id }}" title="Back to programme page">
          <i class="fas fa-angle-left"></i>
          <small>Back to programme</small>
        </router-link>
      </div>
    </div>
    <ol class="sidebar-panel-nav">
      <li class="nav-group nav-group-title module-overview">
        <router-link :to="{ name: 'module_landing', params: { moduleId: this.moduleDetails.id } }">
          <i class="fas fa-home"></i><span>Module Overview</span>
        </router-link>
        <k-progress v-if="chapterCompletionScores.length" class="squared module-progress"
          shape="bar"
          :percentage="moduleCompletionPercentage"
          :precision="1"
          :show-bar-text="true"
          :valueMax="chapterCompletionScores.length"
          customText="Completed"
          ariaLabel="Overall Module Progress"
          size="small"
        ></k-progress>
      </li>
      <li>
        <chapter v-for="chapter in chapters"
          :key="chapter.title"
          :chapter="chapter">
        </chapter>
      </li>
      <li v-if="knowledgeBaseEnabled" class="nav-group nav-group-title knowledge-base">
        <router-link :to="{ name: 'module_knowledge_base' }">
          <i class="k-asset-icon fa-duotone fa-comments fa-swap-opacity"></i><span>Knowledge Base</span>
        </router-link>
        <span v-if="threads" class="count-bubble">{{ threads.length }}</span>
      </li>
    </ol>
  </div>
</template>

<style scoped>
.module-overview {
  display: flex;
  flex-direction: column;
}

.module-progress {
  width: 100%;
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import SidebarMixin from '../../mixins/sidebar-mixin';
import Chapter from './chapter.vue';
import KProgress from '../../components/k-progress.vue';

export default {
  components: {
    chapter: Chapter,
    KProgress,
  },

  mixins: [ErrorMixin, SidebarMixin],

  props: {
    sidebarOpen: {
      type: Boolean,
    },
  },

  data() {
    return {
      moduleDetailsReady: false,
      threadsReady: false,
      chaptersReady: false,
      initialLoadFinished: false,
    };
  },

  beforeMount() {
    this.$sidebar.setVisibility(true);
    this.$sidebar.ready = false;
    this.$sidebar.setModuleId(this.moduleId);
    this.addCallbacks();
    this.loadModuleData();
  },

  watch: {
    allReady() {
      if (this.allReady) {
        if (!this.initialLoadFinished) {
          this.initialLoadFinished = true;
        }
        this.$sidebar.ready = true;
      } else {
        this.$sidebar.ready = false;
      }
    },

    route() {
      // Refresh KB anytime route changes
      if (this.knowledgeBaseEnabled) {
        this.$logger.info('Route changed, refreshing threads');
        this.getThreads();
      }
    },

    moduleId() {
      this.$sidebar.setModuleId(this.moduleId);
    },

    moduleDetailsReady() {
      if (this.moduleDetailsReady && this.moduleDetails && this.knowledgeBaseEnabled) {
        this.getThreads();
      }
    },
  },

  computed: {
    route() {
      return this.$route;
    },

    moduleId() {
      return Number(this.$route.params.moduleId);
    },

    knowledgeBaseEnabled() {
      return Boolean(this.moduleDetails && this.moduleDetails.knowledge_base_enabled);
    },

    kloudEnabled() {
      return Boolean(this.moduleDetails && this.moduleDetails.kloud_enabled);
    },

    apiEndpoint() {
      if (this.moduleId) {
        return `/api/curriculum/modules/${this.moduleId}`;
      }
      return undefined;
    },

    allReady() {
      if (this.knowledgeBaseEnabled) {
        return this.moduleDetailsReady && this.chaptersReady && this.threadsReady;
      }
      return this.moduleDetailsReady && this.chaptersReady;
    },
  },

  methods: {
    getModuleDetails() {
      this.$logger.info('Getting module details', { moduleId: this.moduleId }, true);
      this.moduleDetailsReady = false;
      this.$http.get(this.apiEndpoint)
        .then(response => {
          this.$logger.info('Got module details', { moduleId: this.moduleId });
          this.$sidebar.moduleDetails = response.data;
        }).catch(err => {
          if (err.response) {
            if (this.$http.errIn(err, [404])) {
              this.$logger.warn('Module not found', { moduleId: this.moduleId });
              this.$router.push({ name: '404', params: { pathMatch: '' } });
            } else if (this.$http.errIn(err, [403])) {
              this.$logger.warn('Access to restricted content', { moduleId: this.moduleId });
              this.$router.push({ name: '404', params: { pathMatch: '' } });
            } else if (this.$http.isWarning(err)) {
              this.$logger.warn('Error getting module details for sidebar', { moduleId: this.moduleId }, err);
              this.showError(err, true);
            } else {
              this.$logger.error('Error getting module details for sidebar', { moduleId: this.moduleId }, err);
              this.showError(err, true);
            }
          }
        }).then(() => {
          this.moduleDetailsReady = true;
        });
    },

    getChapters() {
      this.$logger.info('Getting module chapters', { moduleId: this.moduleId }, true);
      this.chaptersReady = false;
      this.$http.get(`${this.apiEndpoint}/chapters`)
        .then(response => {
          this.$sidebar.chapters = response.data.chapters;
          this.$logger.info('Got module chapters', { moduleId: this.moduleId });
        }).catch(err => {
          if (!this.$http.isWarning(err)) {
            this.$logger.error('Error getting module chapters', { moduleId: this.moduleId }, err);
            this.showError(err, true);
          } else {
            this.$logger.warn('Module not found', { moduleId: this.moduleId });
          }
        }).then(() => {
          this.chaptersReady = true;
        });
    },

    getThreads() {
      if (!(this.moduleDetailsReady && this.knowledgeBaseEnabled)) {
        return;
      }
      this.$logger.info('Getting module KB questions', { moduleId: this.moduleId }, true);
      this.threadsReady = false;
      this.$http.get(`/api/curriculum/knowledge/${this.moduleId}/threads`).then(result => {
        this.$sidebar.threads = result.data.threads;
        this.$logger.info('Got module KB questions', { moduleId: this.moduleId });
      }).catch(err => {
        if (err.response) {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Threads not found', { moduleId: this.moduleId });
          } else {
            this.$logger.error('Error getting threads', { moduleId: this.moduleId }, err);
            this.showError(err, true);
          }
        }
      }).then(() => {
        this.threadsReady = true;
      });
    },

    loadModuleData() {
      this.$logger.info('Loading module sidebar data', { moduleId: this.moduleId }, true);
      this.$Loading.start();
      if (this.moduleId) {
        this.getModuleDetails();
        this.getThreads();
        this.getChapters();
      }
    },

    addCallbacks() {
      this.$sidebar.setLoadModuleDataCallback(this.loadModuleData);
      this.$sidebar.setRefreshThreadsCallback(this.getThreads);
    },
  },
};
</script>

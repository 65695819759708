<template>
  <div :class="isDashboard ? store.sidebarClass : null" id="project-overview" class="content-with-spacing">
    <section v-if="metaReady" class="heading-container" :class="{'no-margin' : isDashboard}">
      <div class="heading-content">
        <div class="title-container">
          <h1>{{ projectName }}</h1>
          <span v-if="competitionMode && pakStarted" class="submission-timer"><k-timer :deadline="competitionEndTime"></k-timer></span>
          <span v-if="expired" class="submission-closed-notice">(Submissions Closed)</span>
          <span v-if="projectMeta && projectMeta.end_date" class="due-date">Due: <b>{{ parseTimestamp(projectMeta.end_date, undefined, "do MMM yyyy") }}</b></span>
        </div>
        <div class="header-dropdown">
          <feedback-controls
            v-if="isApp"
            :started="pakStarted"
            :project-meta="projectMeta || {}"
            :kloud-enabled="kloudEnabled"
            :competition-finished="competitionFinished"
            :competitionMode="competitionMode"
            @copyhttp="copyHttp"
            @notebookUpload="openNotebookUpload"
            @pakReset="openPakResetConfirmation"
            @copyssh="copySsh"
            :expired="expired"
            @fork="forkProject">
          </feedback-controls>
          <intersection-observer observed-element="start-assignment"
            @on-intersection-element="onElementInView"
          ></intersection-observer>
        </div>
      </div>
    </section>
    <template v-if="isDashboard && !pakStarted && metaReady">
      <empty-placeholder v-if="isDashboard" info="The student has not started this pak yet"></empty-placeholder>
    </template>
    <template v-else>
      <k-nav-tabs v-if="metaReady" :currentTab="currentPage" :tabs="FEEDBACK_TABS"></k-nav-tabs>
      <!-- Content -->
      <div v-if="allReady">
        <router-view v-bind="pageProps" :key="currentPage" @updating-feedback="handleFeedbackUpdating"></router-view>
        <div class="bottom-content" v-if="currentPage === 'pak_ov_instructions' && !pakStarted && !isInViewElement">
          <start-project @fork="forkProject"></start-project>
        </div>
      </div>
    </template>
    <!-- Upload Modal -->
    <k-modal :show="showNotebookUploadBox" @close="closeNotebookUpload" v-if="notebookOnly" id="notebook-upload-popup">
      <template #header>
        <h3>Upload your notebook</h3>
      </template>
      <template #body>
        <div class="dropfield-accept">
          <dropfield @file="handleNotebookUpload" accept=".ipynb,application/x-ipynb+json" :immediate-post="false" ref="notebookdrop"></dropfield>
        </div>
      </template>
      <template #footer>
        <button class="modal-default-button btn btn-primary" @click="closeNotebookUpload">
          <i class="fas fa-times"></i> Cancel
        </button>
      </template>
    </k-modal>

    <!--Reset Assignment Modal -->
    <k-modal v-if="metaReady" :show="showPakResetBox" @close="closePakReset" id="reset-pak-popup">
      <template #header>
        <h3><i class="highlight fas fa-exclamation-triangle"></i> RESET: {{ projectName }}</h3>
      </template>
      <template #body>
        <div class="confirm-removal-body">
          <p>You are about to <b class="highlight">RESET YOUR CODE</b> for assignment <b class="highlight">{{ projectName }}</b>.</p>
          <p>Type <b class="highlight">{{ projectName.toUpperCase() }}</b> in uppercase to confirm the reset, otherwise click cancel!</p>
          <p>If you have the assignment open in the KLOUD learning environment,
            <b class="highlight" v-if="kloudEnabled">the session will close after confirming</b>.
          </p>
          <form >
            <input v-model="pakResetConfirm" placeholder="Type assignment name..." class="reset-pak-confirm-input k-text-primary">
          </form>
        </div>
      </template>
      <template #footer>
        <button class="modal-return-button btn btn-outlined" @click="closePakReset">
          Cancel
        </button>
        <button class="btn btn-danger modal-reset-button" @click="resetPak" :disabled="!resetPakConfirmMatch">
          Reset Assignment
        </button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
#reset-pak-popup input.reset-pak-confirm-input {
  background-color: var(--input-background);
  border: var(--input-border);
  padding: 8px 10px;
  border-radius: 4px;
  width: 100%;
}

.submission-timer,
.submission-closed-notice {
  display: block;
  font-size: 1em;
  color: var(--kate-type-danger);
}

.heading-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}

.heading-content {
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 20px auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.heading-container .title-container {
  flex: 1;
  align-self: center;
}

.header-dropdown {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  text-align: right;
}

#content .title-container h1 {
  padding: 10px 0;
  margin: 0 !important;
  align-self: center;
}

.due-date {
  padding-top: 5px;
  display: block;
  margin: 0;
}

.due-date b {
  color: var(--kate-type-danger);
}

.bottom-content {
  text-align: right;
  margin-top: 20px;
}

.highlight {
  color: var(--kate-2);
}
/* ==== Current ==== */
@media (max-width: 1024px) {
  .heading-container {
    display: block;
  }

  .heading-container .title-container {
    flex: 1 1 100%;
  }

  #content .heading-container .title-container h1 {
    font-size: 1.8em;
  }

  .submission-closed-notice {
    text-align: center;
  }

  .header-dropdown {
    width: 100%;
  }
}
</style>

<script>
import { marked } from 'marked';
import useGlobalStore from '../stores/global';
import TimeMixins from '../mixins/time-mixins';
import RepoControl from '../mixins/repo-control-mixins';
import FeedbackControls from './feedback-controls.vue';
import KModal from '../components/k-modal.vue';
import KTimer from '../components/k-timer.vue';
import DropField from '../components/dropfield.vue';
import KStartProject from './k-start-project.vue';
import NotebookMixin from '../mixins/notebook-mixin';
import getOrNull from '../modules/get-or-null';
import IntersectionObserver from '../components/k-intersection-observer.vue';
import EmptyPlaceholder from '../components/empty-placeholder.vue';
import KNavTabs from '../components/k-nav-tabs.vue';

export default {
  mixins: [TimeMixins, RepoControl, NotebookMixin],

  components: {
    'feedback-controls': FeedbackControls,
    dropfield: DropField,
    'k-modal': KModal,
    'k-timer': KTimer,
    'start-project': KStartProject,
    'intersection-observer': IntersectionObserver,
    EmptyPlaceholder,
    KNavTabs,
  },

  props: {
    sidebarReady: {
      type: Boolean,
      default: true, // if not specified (e.g. dashboard) assume true
    },
    projectDetails: {
      type: Object,
    },
    moduleDetails: {
      type: Object,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      studentName: undefined,
      solutionAvailabilityChecked: false,
      metaReady: false,
      userReady: false,
      notebookReady: false,
      projectMeta: undefined,
      solution: undefined,
      solutionAvailable: undefined,
      showNotebookUploadBox: false,
      showPakResetBox: false,
      pakResetConfirm: '',
      latestCommitTimeStamp: undefined,
      isInViewElement: false,
      now: undefined,
      unforking: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    if (this.isApp) {
      this.getLatestTimeStamp();
      this.getRepoAccessToken();
    } else {
      // Need user info on dashboard
      this.getUserProfile();
    }
  },

  mounted() {
    this.now = new Date();
    this.refresh = setInterval(this.updateTime, 1000);
    this.getProjectMeta();
  },

  beforeUnmount() {
    if (this.refresh !== undefined) {
      clearInterval(this.refresh);
    }
  },

  watch: {
    allReady(val) {
      if (val) {
        this.registerPageCrumbs();
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
    currentPage() {
      // re-register crumbs whenever tab changes
      this.registerPageCrumbs();
    },
    routeQuery() {
      if (this.routeQuery?.reset) {
        this.getProjectMeta();
        this.$sidebar.loadModuleData();
      }
    },
  },

  computed: {
    showKloud() {
      return (this.kloudEnabled && this.projectMeta && this.projectMeta.kloud_compatible && this.projectMeta.notebook_only);
    },

    cacheKey() {
      return `k-ide-${this.modulePakId}`;
    },
    moduleId() {
      return this.$route.params.moduleId;
    },
    endDate() {
      if (!this.projectMeta || !this.projectMeta.end_date) {
        return undefined;
      }
      return this.getDate(this.projectMeta.end_date);
    },
    competitionMode() {
      return Boolean(this.projectMeta && this.projectMeta.time_limit);
    },
    competitionEndTime() {
      if (this.competitionMode && this.pakStarted) {
        const limit = this.projectMeta.time_limit.split(':');
        return this.addToDate(this.getDate(this.projectMeta.fork_date), { hours: parseInt(limit[0], 10), minutes: parseInt(limit[1], 10) });
      }
      return undefined;
    },
    competitionFinished() {
      if (this.competitionMode) {
        return this.now > this.competitionEndTime;
      }
      return false;
    },
    expired() {
      return Boolean(getOrNull('deprecated', this.projectMeta));
    },
    studentId() {
      return this.$route.params.studentId; // undefined on app
    },
    modulePakId() {
      return this.$route.params.modulePakId;
    },
    kloudEnabled() {
      return getOrNull('kloud_enabled', this.moduleDetails);
    },
    isDashboard() {
      return this.store.appName === 'dashboard';
    },
    isApp() {
      return this.store.appName === 'app';
    },
    solutionReleased() {
      return Boolean(getOrNull('solution_released', this.projectMeta));
    },
    pakStarted() {
      return Boolean(this.projectMeta?.repo_path);
    },
    instructions() {
      const inst = getOrNull('instructions.readme', this.projectMeta);
      return inst ? marked(inst) : undefined;
    },
    allReady() {
      let ready;
      if (this.isDashboard && !this.pakStarted) {
        return this.metaReady && this.userReady;
      }
      if (this.isDashboard) {
        ready = this.metaReady && this.userReady && this.solutionAvailabilityChecked;
        return this.notebookOnly ? ready && this.notebookReady : ready;
      }
      ready = this.repoReady && this.solutionAvailabilityChecked && this.metaReady && this.sidebarReady && !this.unforking;
      if (this.pakStarted && this.notebookOnly) {
        // If PAK has been started and it's a notebook, we also want to wait for the notebook to download
        return this.notebookReady && ready;
      }
      return ready;
    },
    routeQuery() {
      return this.$route.query;
    },
    // Same component for editing or solution
    showIde() {
      return this.currentPage === 'ide' || this.currentPage === 'solution';
    },
    notebookOnly() {
      return Boolean(getOrNull('notebook_only', this.projectMeta));
    },
    resetPakConfirmMatch() {
      if (!this.pakResetConfirm || !this.projectMeta || !this.projectName) {
        return false;
      }
      return this.pakResetConfirm === this.projectName.toUpperCase();
    },
    FEEDBACK_TABS() {
      const tabs = [];
      // App
      if (this.isApp) {
        tabs.push({ name: 'Get Started', key: 'instructions', routeName: 'pak_ov_instructions' });
        if (this.pakStarted) {
          tabs.push(
            { name: 'Feedback', key: 'feedback', routeName: 'pak_ov_feedback' },
          );
          if (this.notebookOnly) {
            tabs.push({ name: 'Notebook', key: 'notebook', routeName: 'pak_ov_notebook' });
          } else {
            tabs.push({ name: 'Web-Ide', key: 'ide', routeName: 'pak_ov_web_ide' });
          }
          if (this.projectMeta.show_leaderboard) {
            tabs.push(
              { name: 'Leaderboard', key: 'leaderboard', routeName: 'pak_ov_leaderboard' },
            );
          }
          if (this.solutionAvailable && (this.solutionReleased || this.$permissions.hasPermission('view_solutions_before_release'))) {
            if (this.notebookOnly) {
              tabs.push({ name: 'Solution', key: 'solution', routeName: 'pak_ov_notebook_solution' });
            } else {
              tabs.push({ name: 'Solution', key: 'solution', routeName: 'pak_ov_ide_solution' });
            }
          }
        }
      }

      // Dashboard
      if (this.isDashboard) {
        if (this.pakStarted) {
          tabs.push({ name: 'Feedback', key: 'feedback', routeName: 'dash_pak_ov_feedback' });
          if (this.notebookOnly) {
            tabs.push({ name: 'Notebook', key: 'notebook', routeName: 'dash_pak_ov_notebook' });
          } else {
            tabs.push({ name: 'Web-Ide', key: 'ide', routeName: 'dash_pak_ov_web_ide' });
          }
          if (this.solutionAvailable) {
            if (this.notebookOnly) {
              tabs.push({ name: 'Solution', key: 'solution', routeName: 'dash_pak_ov_notebook_solution' });
            } else {
              tabs.push({ name: 'Solution', key: 'solution', routeName: 'dash_pak_ov_ide_solution' });
            }
          }
        }
      }
      return tabs;
    },
    projectName() {
      return this.projectMeta ? this.projectMeta.name : '';
    },
    isResetting() {
      return this.unforking;
    },
    pageProps() {
      // Web IDE
      if (this.$route.name === 'pak_ov_web_ide' || this.$route.name === 'dash_pak_ov_web_ide') {
        return {
          projectMeta: this.projectMeta,
          enableCache: !this.isDashboard,
          isSolution: false,
          isExpired: this.competitionFinished || this.expired,
          isDashboard: this.isDashboard,
          studentId: this.studentId ? parseInt(this.studentId, 10) : this.studentId,
          isResetting: this.isResetting,
        };
      }
      if (this.$route.name === 'pak_ov_ide_solution' || this.$route.name === 'dash_pak_ov_ide_solution') {
        return {
          projectMeta: this.projectMeta,
          isSolution: true,
          isExpired: this.expired,
          isDashboard: this.isDashboard,
        };
      }
      // Notebook
      if (this.$route.name === 'pak_ov_notebook' || this.$route.name === 'dash_pak_ov_notebook') {
        return {
          projectMeta: this.projectMeta,
          projectDetails: this.projectDetails,
          notebook: this.currentNotebook,
          submissionDate: this.latestCommitTimeStamp,
          isSolution: false,
          isDashboard: this.isDashboard,
          studentId: this.studentId ? parseInt(this.studentId, 10) : this.studentId,
        };
      }

      if (this.$route.name === 'pak_ov_notebook_solution' || this.$route.name === 'dash_pak_ov_notebook_solution') {
        return {
          projectMeta: this.projectMeta,
          notebookFilename: this.notebookFilename,
          isSolution: true,
          isDashboard: this.isDashboard,
        };
      }
      // Feedback
      if (this.$route.name === 'pak_ov_feedback' || this.$route.name === 'dash_pak_ov_feedback') {
        return {
          projectMeta: this.projectMeta,
          projectDetails: this.projectDetails,
          notebook: this.currentNotebook,
        };
      }
      // Leaderboard
      if (this.$route.name === 'pak_ov_leaderboard') {
        return {
          projectMeta: this.projectMeta,
          projectDetails: this.projectDetails,
        };
      }
      // Instructions
      return {
        projectMeta: this.projectMeta,
        projectDetails: this.projectDetails,
        instructions: this.instructions,
      };
    },
    currentPage() {
      return this.$route.name;
    },
  },

  methods: {
    registerPageCrumbs() {
      let crumbs;
      if (this.isDashboard) {
        crumbs = [
          {
            text: this.studentName || this.studentId,
            path: {
              name: 'dashboard_students',
              params: { studentId: this.studentId },
            },
          },
          { text: this.projectName || this.$route.params.modulePakId, active: true },
        ];
      }
      this.$crumbs.register(crumbs);
    },
    updateTime() {
      this.now = new Date();
    },
    getUserProfile() {
      this.userReady = false;
      this.$http.get(`/api/profile/users/${this.studentId}`).then(res => {
        this.studentName = res.data.full_name;
      }).catch(() => {}).then(() => {
        this.userReady = true;
      });
    },
    handleNotebookUpload(file) {
      this.closeNotebookUpload();
      this.$Loading.minimal();
      this.loadNotebookFromFileList(file).then(() => {
        this.$Loading.finish();
        this.getSubmissionEvent();
      });
      this.$refs.notebookdrop.reset();
    },
    openNotebookUpload() {
      this.showNotebookUploadBox = true;
    },
    closeNotebookUpload() {
      this.showNotebookUploadBox = false;
    },
    openPakResetConfirmation() {
      this.showPakResetBox = true;
    },
    closePakReset() {
      this.showPakResetBox = false;
      this.pakResetConfirm = '';
    },
    getProjectMeta() {
      this.$logger.info('Getting project info', { modulePakId: this.modulePakId }, true);
      this.metaReady = false;
      this.$http.get(this.pakMetaEndpoint).then(response => {
        this.$logger.info('Got project info', { modulePakId: this.modulePakId });
        this.projectMeta = response.data;
        this.$logger.info(`Project ${this.pakStarted ? 'started' : 'not started'}: repo path is ${this.projectMeta?.repo_path}`);
        if (this.notebookOnly && this.pakStarted) {
          // Load notebook here so it isn't loaded each time you switch between tabs
          this.downloadNotebook();
        }
        this.checkSolutionAvailable();
      }).catch(err => {
        if (this.$http.errIn(err, [404]) && this.isDashboard) {
          this.$logger.autowarn('Project meta not found', { modulePakId: this.modulePakId }, err);
          this.$router.push({ name: '404' });
        } else if (this.$http.isWarning(err)) {
          this.$logger.autowarn('Could not get project meta', { modulePakId: this.modulePakId }, err);
        } else {
          this.$logger.error('Error getting project meta', { modulePakId: this.modulePakId }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.metaReady = true;
      });
    },
    forkProject() {
      if (this.isDashboard) {
        return;
      }
      this.$logger.info('Forking project', { modulePakId: this.modulePakId }, true);
      this.$Loading.start();
      this.metaReady = false;
      this.$http.post(`/api/curriculum/pak/${this.modulePakId}/fork`).then(() => {
        this.$logger.info('Forked project', { modulePakId: this.modulePakId });
        this.getProjectMeta();
        this.$sidebar.loadModuleData();
      }).catch(err => {
        this.$logger.error('Error forking project', { modulePakId: this.modulePakId }, err);
        this.showError(err, true);
        this.metaReady = true;
      });
    },
    resetPak() {
      this.closePakReset();
      this.unforking = true;
      this.$logger.info('Resetting PAK', { modulePakId: this.modulePakId }, true);
      this.$http.delete(`/api/curriculum/pak/${this.modulePakId}/unfork`)
        .then(() => {
          this.$logger.info('Unforked PAK', { modulePakId: this.modulePakId }, true);
          this.$cache.removeFromStorageFullCacheKey(this.cacheKey);
          if (this.$route.name !== 'pak_ov_instructions') {
            this.$router.push({
              name: 'pak_ov_instructions',
              query: {
                reset: true,
              },
              params: {
                moduleId: this.moduleId,
                modulePakId: this.modulePakId,
              },
            });
          } else {
            this.getProjectMeta();
            this.$sidebar.loadModuleData();
          }
        })
        .catch(err => {
          this.$logger.autowarn('Error unforking PAK', { modulePakId: this.modulePakId }, err);
          this.showError(err, true);
        })
        .then(() => {
          this.unforking = false;
          this.$ktoast.success('Your assignment has been reset. Make a fresh submission to reset your feedback', { goAway: 5000 });
        });
    },
    updateFilters(filename, functionName) {
      this.currentFilePath = filename;
      this.currentFunctionName = functionName;
    },
    getFirstFile() {
      return Object.keys(this.layout)[0];
    },
    checkSolutionAvailable() {
      this.$logger.info('Checking if solution available for pak', { modulePakId: this.modulePakId }, true);
      this.solutionAvailabilityChecked = false;
      this.$http.get(`/api/curriculum/pak/${this.modulePakId}/solution/tree`).then(() => {
        this.$logger.info('Solution available for pak', { modulePakId: this.modulePakId });
        this.solutionAvailable = true;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          if (getOrNull('response.data.err', err) === 'solution_not_released') {
            this.$logger.info('Could not get solution for pak (solution not released)', { modulePakId: this.modulePakId });
            this.solutionAvailable = true;
          } else {
            this.$logger.info('Could not get solution for pak (solution unavailable)', { modulePakId: this.modulePakId });
            this.solutionAvailable = false;
          }
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn('Error getting project solution', { modulePakId: this.modulePakId }, err);
          this.showError(err, true);
        } else {
          this.$logger.error('Error checking for solution for pak', { modulePakId: this.modulePakId }, err);
          this.solutionAvailable = false;
        }
      }).then(() => {
        this.solutionAvailabilityChecked = true;
      });
    },
    getSubmissionEvent() {
      if (this.isDashboard) {
        return;
      }
      this.$http.get(`/api/events/submissions/${this.modulePakId}`).then(response => {
        this.latestCommitTimeStamp = response.data.timestamp;
      }).catch(err => {
        this.showError(err, true);
      });
    },
    getLatestTimeStamp() {
      this.$http.get(`/api/stats/${this.modulePakId}/latest_timestamp`).then(response => {
        this.latestCommitTimeStamp = response.data.timestamp;
      }).catch(err => {
        this.showError(err, true);
      });
    },
    handleFeedbackUpdating() {
      if (this.notebookOnly) {
        // For notebook PAKs we want to re-download the notebook when feedback updates to ensure
        // the current notebook on KATE matches the latest submission via KLOUD
        this.downloadNotebook();
      }
    },
    onElementInView(value) {
      this.isInViewElement = value;
    },
  },
};
</script>

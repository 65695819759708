<template>
  <div id="app" :data-theme="theme">
    <header>
      <navbar></navbar>
    </header>
    <div id="wrapper">
      <div id="content" class="content-wrapper">
        <sidebar></sidebar>
        <navcrumb></navcrumb>
        <router-view v-if="ready"></router-view>
        <k-loading-component></k-loading-component>
        <k-screenshot></k-screenshot>
      </div>
      <k-footer></k-footer>
    </div>
  </div>
</template>

<script>
import routes from '@kate-routes';
import useGlobalStore from './stores/global';
import useFeatureFlagStore from './stores/feature-flag';
import useThemeStore from './stores/theme';
import Navbar from './layout/navbar.vue';
import Navcrumb from './layout/navcrumb.vue';
import KScreenshot from './layout/k-screenshot.vue';
import Footer from './layout/footer.vue';
import Sidebar from './sidebar/sidebar.vue';
import getOrNull from './modules/get-or-null';
import getRoutesWithProperty from './modules/get-routes-with-property';
import './styles';

const PUBLIC_PAGES = getRoutesWithProperty(routes, 'kate_is_public');

export default {
  components: {
    Navbar,
    'k-footer': Footer,
    Navcrumb,
    KScreenshot,
    Sidebar,
  },

  data: () => ({
    ready: false,
    store: useGlobalStore(),
    themeStore: useThemeStore(),
    featureFlagStore: useFeatureFlagStore(),
  }),

  created() {
    this.$logger.debug('Created root component');
    this.registerRouterCallbacks();
  },

  beforeMount() {
    this.$Loading.start();
    let startTime;
    // Safari doesn't have timeorigin, so set the start time as now
    // It'll be inaccurate to a few seconds, but best we can do for now
    if (window.performance.timeOrigin) {
      startTime = window.performance.timeOrigin / 1000;
    } else {
      startTime = new Date() / 1000;
    }
    this.$logger.debug('Initialised root component', {
      pageStartTime: startTime,
    });
    const kateStatus = this.checkKateStatus();
    const featureFlagClient = this.$featureFlags.getClient();
    Promise.all([kateStatus, featureFlagClient]).then(() => {
      this.$logger.info('Maintenance status checked and feature flag client initialised');
      this.getUserProfile().then(() => {
        const resolvedRoute = this.navigationGuard(this.$route);
        if (resolvedRoute) {
          // Navigation guard requires a redirect - wait for that to resolve before setting ready
          // to true and rendering the router-view to display the corresponding route component
          this.$router.push(resolvedRoute).then(() => {
            this.ready = true;
          });
        } else {
          // No redirect required - set ready to true rendering the router-view
          this.ready = true;
        }
      });
    });
  },

  computed: {
    theme() {
      return this.themeStore.theme;
    },
  },

  methods: {
    routerBeforeEach(to, from) {
      this.$logger.resetRouteId();
      this.$logger.info(`Moving to page ${to.name}`, {
        from: from.fullPath,
        to: to.fullPath,
      }, true);
      this.$httpCancel();
      // If resolvedRoute is defined, the nav guard says the user should be redirected to a different page
      const resolvedRoute = this.navigationGuard(to);
      // Returning undefined from router hook means the hook completed successfully and routing
      // will continue onto the next hook using the current route. If the hook returns a route object, the
      // routing will start again with the returned route
      return resolvedRoute;
    },
    routerAfterEach(to, from, failure) {
      this.$Loading.finish();
      // Clear crumbs if we're routing to a new page and there was no navigation failure
      if (to.fullPath !== from.fullPath && !failure) {
        this.$crumbs.clear();
      }
      // Hook completed successfully - continue with routing and move to next hook
      // or if there was a failure, routing will stop here
      return undefined;
    },
    registerRouterCallbacks() {
      // Register the router beforeEach and afterEach hooks
      this.$router.beforeEach((to, from) => { this.routerBeforeEach(to, from); });
      this.$router.afterEach((to, from, failure) => { this.routerAfterEach(to, from, failure); });
    },
    backupPageForMaintenance(to) {
      this.$cache.set('maintenance-prev-page', {
        name: to.name,
        params: to.params,
        query: to.query,
      }, 3600);
    },
    checkKateStatus() {
      return this.$http.get('/api/status').then(() => {}).catch(err => {
        if (getOrNull('response.data.err', err) === 'maintenance') {
          this.store.$patch({ inMaintenance: true });
          this.backupPageForMaintenance(this.$route);
          this.$router.push({ name: 'maintenance' });
        }
      });
    },
    getUserProfile() {
      this.$logger.info('Getting user profile info');
      return this.$http.get('/api/profile/user')
        .then(response => {
          this.$logger.info('Got user profile info');
          this.featureFlagStore.loadUser(response.data.id, response.data.email);
          this.store.logIn(response.data);
          this.themeStore.resolveThemePreference(response.data);
          if (!this.store.termsAccepted && this.store.appName === 'app') {
            this.$logger.info('Redirecting to policy page', undefined, true);
            this.$router.push({ name: 'policy_accept_page' });
          }
        })
        .catch(err => {
          this.$logger.warn('Failed to get user profile info', undefined, err);
        });
    },
    navigationGuard(to) {
      // Check if KATE is in maintenance mode
      if (to.name !== 'maintenance' && this.store.inMaintenance) {
        this.backupPageForMaintenance(to);
        this.$logger.info('Redirecting to maintenance page', undefined, true);
        return { name: 'maintenance' };
      }
      // User must be logged in to access any page other than the public pages
      if (!this.store.isLoggedIn && PUBLIC_PAGES.indexOf(to.name) === -1) {
        this.$logger.info('Redirecting to login page', undefined, true);
        return { name: 'login_page', query: { next: encodeURIComponent(to.fullPath) } };
      }
      // On dashboard, user must have correct permissions to access the page
      if (this.store.isDashboard && !this.$permissions.hasDashboardPagePermission(to.name)) {
        return { name: '403' };
      }
      return undefined;
    },
  },
};
</script>

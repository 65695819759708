<template>
  <div v-if="ready">
    <div v-if="isPdf" class="file-container">
      <iframe :data="luFileSignedUrl" type="application/pdf" :src="luFileSignedUrl" title="File display">
        <embed :src="luFileSignedUrl" type="application/pdf" />
      </iframe>
    </div>
    <div v-if="isNotebook && notebookContent" class="notebook-display-container">
      <ipynb :ipynb="notebookContent"></ipynb>
    </div>
  </div>
</template>

<style scoped>
.file-container {
  height: 100%;
  text-align: center;
}

.file-container object,
.file-container iframe {
  width: 100%;
  height: 100%;
}

.notebook-display-container {
  margin: 0 5px;
}
</style>

<script>
import ErrorMixin from '../mixins/error-mixins';
import NotebookRenderer from '../notebook/notebook-renderer.vue';

export default {
  mixins: [ErrorMixin],

  components: {
    ipynb: NotebookRenderer,
  },

  props: {
    currentFile: {
      type: Object,
      required: true,
    },
    moduleLuId: {
      type: Number,
    },
  },

  data() {
    return {
      luFileSignedUrl: undefined,
      notebookContent: undefined,
      isLoading: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    if (this.isNotebook) {
      this.getFileContent();
    } else if (this.isPdf) {
      this.getSignedUrl();
    } else {
      this.isLoading = false;
    }
  },

  watch: {
    currentFile() {
      this.luFileSignedUrl = undefined;
      this.notebookContent = undefined;
      if (this.isNotebook) {
        this.getFileContent();
      } else {
        this.getSignedUrl();
      }
    },
    isLoading() {
      if (this.isLoading) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    ready() {
      return !this.isLoading;
    },
    isNotebook() {
      return this.currentFile.name.slice(-6) === '.ipynb';
    },
    isPdf() {
      return this.currentFile.name.slice(-4) === '.pdf';
    },
    encodedFilePath() {
      return encodeURIComponent(this.currentFile.path);
    },
  },

  methods: {
    getFileContent() {
      this.isLoading = true;
      this.$logger.info('Getting file content of a LU part', { module_lu_id: this.moduleLuId }, true);
      this.$http.get(`/api/curriculum/learning_unit/${this.moduleLuId}/content?path=${this.encodedFilePath}`)
        .then(response => {
          this.$logger.info('Got file content of a LU part', { module_lu_id: this.moduleLuId }, true);
          this.notebookContent = JSON.parse(response.data.file_content);
        })
        .catch(err => {
          this.$logger.error('Error getting file content of a LU part', { module_lu_id: this.moduleLuId }, err);
          this.showError(err, true);
        }).then(() => {
          this.isLoading = false;
        });
    },
    getSignedUrl() {
      this.isLoading = true;
      this.$logger.info('Getting LU file source with signed URL', { module_lu_id: this.moduleLuId }, true);
      this.$http.get(`/api/curriculum/learning_unit/${this.moduleLuId}/src?path=${this.encodedFilePath}`)
        .then(response => {
          this.$logger.info('Got LU file source with signed URL', { module_lu_id: this.moduleLuId });
          this.luFileSignedUrl = response.data.file_src;
        }).catch(err => {
          this.$logger.error('Error getting signed URL', { module_lu_id: this.moduleLuId, file: this.encodedFilePath }, err);
          this.showError(err, true);
        }).then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<template>
  <div class="chat-box-container" :class="[showChat ? 'expanded' : '', !themeStore.hideChatBotPopup ? 'animated-btn' : '']">
    <button type="button" class="btn btn-primary k-chat-btn" title="Toggle" @click="toggleChat" aria-label="Toggle chat button">
      <img src="../../../img/icons/kate-bot-chat-colours.svg" alt="KATE bot avatar"/>
    </button>
    <div class="chat-box-content">
      <k-chat-msg :message="welcomeMessage" :showActions="false"></k-chat-msg>
      <k-chat-msg
          v-for="message in messages"
          :key="message"
          :class='["message", { right: !message.fromChatbot }]'
          :message="message"
          :showActions="showActionsForMessages(message)"
      />
      <k-chat-msg v-if="isLoadingReply"
        :loading="isLoadingReply"
        :class='["message", { right: false }]'
        :message="{text: 'Loading...', fromChatbot: true}"
        ></k-chat-msg>
      <form class="message-input-container" :disabled="isDisabled">
        <label for="k-chatbox-input">
          <input id="k-chatbox-input" class="form-control message-input"
            v-model="text"
            type="input"
            placeholder="Type message...">
        </label>
        <button :disabled='text === "" || isDisabled' type="submit" class="submit-button" @click.prevent="postQuery">Send</button>
      </form>
    </div>
    <k-popup v-if="!themeStore.hideChatBotPopup"
      @close="closePopup"
      :customClass="'info-popup'"
      :show="!themeStore.hideChatBotPopup"
      :title="'Hi there, I\'m KATE!'"
      :position="['top', 'left', 'bottom-left']"
      :body="`How can I help you today?`">
    </k-popup>
  </div>
</template>

<style scoped>
.chat-box-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 10px 20px 20px;
}

.chat-box-container .chat-box-content {
  transition: right 0.2s ease-in-out;
  background-color: var(--chat-background);
  border: var(--kate-chat-border);
  position: fixed;
  padding: 20px 15px;
  margin-left: auto;
  width: 350px;
  right: -399px;
  bottom: 87px;
  display: flex;
  max-height: 55vh;
  overflow: auto;
  flex-direction: column;
  border-radius: 10px 10px 0;
  z-index: 1;
}

.chat-box-container.expanded .chat-box-content {
  pointer-events: auto;
  box-shadow: var(--box-shadow-alt);
  right: 20px;
  max-height: 55vh;
  overflow: auto;
  transition: right 0.2s ease-in-out;
}

form.message-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
  -webkit-box-flex: 0;
  flex: 0 1 42px;
  margin-top: 15px;
  position: relative;
  border-radius: 20px;
  justify-content: space-between;
  height: 14px;
  border: var(--kate-chat-alt-border);
}

form.message-input-container label {
  flex: 1;
}

.message-input {
  background: none;
  border: none;
  outline: none !important;
  resize: none;
  margin: 0;
  display: flex;
  overflow: hidden;
  border-radius: 20px 0 0 20px;
  box-shadow: unset;
  color: var(--kate-type-primary);
  flex-grow: 1;
}

.submit-button {
  background-color: transparent;
  padding: 8px 15px;
}

.submit-button:hover:disabled,
.submit-button:disabled {
  color: var(--kate-disabled);
  background-color: transparent;
  text-decoration: none;
}

.submit-button:hover {
  text-decoration: underline;
  color: var(--kate-type-accent);
}

.chat-box-container.expanded .k-chat-btn {
  border-radius: 50% 0 50% 50%;
}

.chat-box-container .k-chat-btn {
  color: var(--kate-type-dark);
  box-shadow: -2px 2px 3px rgb(0 0 0 / 0.5);
  border: var(--kate-chat-border);
  background-color: var(--kate-background);
  padding: 8px;
  position: relative;
  z-index: 1;
  border-radius: 50% 50% 0;
}

.animated-btn .k-chat-btn {
  animation: double-pulse 3s ease-in-out;
}

.chat-box-container .k-chat-btn:focus {
  outline: var(--kate-chat-border);
  box-shadow: 0 0 15px 2px var(--kate-primary);
}

.chat-box-container .k-chat-btn img {
  width: 50px;
}

@keyframes double-pulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: -2px 2px 3px rgb(0 0 0 / 0.5);
  }

  20%,
  60% {
    transform: scale(1.1);
    box-shadow: 0 0 15px 2px var(--kate-primary);
  }

  40%,
  80% {
    transform: scale(1);
    box-shadow: -2px 2px 3px rgb(0 0 0 / 0.5);
  }
}
</style>

<script>
import useThemeStore from '../../stores/theme';
import ErrorMixin from '../../mixins/error-mixins';
import KChatMsg from './k-chat-msg.vue';
import { uuid } from '../../modules/uuid-wrapper';
import KPopup from '../k-popup.vue';

export default {
  mixins: [ErrorMixin],

  components: {
    KChatMsg,
    KPopup,
  },

  props: {
    programmeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      themeStore: useThemeStore(),
      showChat: false,
      text: '',
      chatbotResponse: undefined,
      welcomeMessage:
        {
          text: 'Hi there, I\'m KATE! I can answer questions about your programme, explain concepts, help you with a coding task and more. <br /> I\'m currently in Beta so please rate my responses to help me improve 🙂 <br /> What can I help you with today?',
          fromChatbot: true,
        },
      messages: [],
      isLoadingReply: false,
      isDisabled: false,
      sessionId: uuid(),
    };
  },

  methods: {
    closePopup() {
      this.themeStore.$patch({ hideChatBotPopup: true });
      this.updateFrontendSettings();
    },

    toggleChat() {
      this.showChat = !this.showChat;
    },

    showActionsForMessages(message) {
      if (message.isError) {
        return false;
      }
      return true;
    },

    updateFrontendSettings() {
      return this.$http.put('/api/profile/user/frontend_settings', {
        theme: this.themeStore.theme,
        hide_themes_popup: this.themeStore.hideThemesPopup,
        hide_chatbot_popup: this.themeStore.hideChatBotPopup,
      }).then(() => {
        this.$logger.info('User preference saved');
      }).catch(err => {
        this.$logger.warn('Error saving user preference for user', err);
      });
    },

    postQuery() {
      this.isDisabled = true;
      this.messages.push({ text: this.text, fromChatbot: false });
      this.$logger.info('Posting query to chatbot', { programmeId: this.programmeId }, true);
      const payload = {
        query: this.text,
        page_url: this.$route.path,
        session_id: this.sessionId,
        history: this.messages.slice(0, -1).map(message => ({
          source: message.fromChatbot ? 'chatbot' : 'human',
          content: message.text,
        })),
      };
      this.isLoadingReply = true;
      this.text = '';
      this.$http.post(`/api/chatbot/query/${this.programmeId}`, payload).then(result => {
        this.chatbotResponse = result.data;
        this.messages.push({ ...this.chatbotResponse, text: this.chatbotResponse?.answer, fromChatbot: true });
        this.$logger.info('Successfully posted query', { programmeId: this.programmeId });
      }).catch(err => {
        this.$logger.error('Error posting query', { programmeId: this.programmeId }, err);
        this.messages.push({ text: 'There was an error getting a response from the Chatbot. Please try again later.', isError: true, fromChatbot: true });
      }).then(() => {
        this.isLoadingReply = false;
        this.isDisabled = false;
      });
    },
  },
};
</script>

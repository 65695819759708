<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="col-md-12">
        <h3>Add New key</h3>
        <form @submit.prevent>
          <input type="text" id="sshTitleField" class="form-control" placeholder="Title" v-model="sshNewKeyTitle" :disabled="sshTextDisabled">
          <br>
          <textarea id="sshKeyField" class="form-control" rows=3 v-model="sshNewKey" :disabled="sshTextDisabled" placeholder="Starts with ssh-rsa..."></textarea>
          <br>
          <button class="btn btn-primary" id="sshAddNewKeyButton" :disabled="sshbuttonDisabled" @click="uploadNewKey"><span class="fas fa-plus"></span> Add key</button>
        </form>
      </div>
      <div class="col-md-12 key-container">
        <div class="k-table-panel">
          <h3>Your keys</h3>
          <k-table
            :headers="kableHeaders"
            :rows="kableRows"
            :max="10"
            :hideable="false"
            @clicked="tableCallback"
            :panel="false">
          </k-table>
        </div>
      </div>
      <k-modal v-bind:show="confirmModal" @close="closeConfirmModal">
        <template #header>
          <h3 class="modal-title"><i class="fas fa-exclamation-triangle"></i> Are you sure ?</h3>
        </template>
        <template #body>
          Are you sure you want to delete your SSH Key? This cannot be undone.
        </template>
        <template #footer>
          <button class="btn btn-default" @click="closeConfirmModal">Cancel</button>
          <button id="confirmation-button" class="btn btn-danger" @click="confirmDeletion">Delete</button>
        </template>
      </k-modal>
    </div>
  </div>
</template>

<style scoped>
.k-modal {
  text-align: center !important;
}

.modal-title {
  font-size: 23px;
  display: flex;
  align-items: center;
}

.modal-title i {
  color: var(--kate-danger);
  padding-right: 10px;
}

.btn-trash {
  padding: 0;
}

.btn-trash span {
  color: var(--kate-danger);
  font-size: 1.3em;
}

.btn-trash:hover span {
  color: var(--kate-danger-dark);
}

.confirm-deletion-modal .modal-container {
  max-width: 400px;
}

h3 {
  padding: 15px 0;
}

.key-container {
  margin: 25px 0;
}

</style>

<script>
import TimeMixin from '../mixins/time-mixins';
import KModal from '../components/k-modal.vue';
import KTable from '../components/k-table.vue';
import getIcon from '../modules/kate-table-icons';

export default {
  mixins: [TimeMixin],

  components: {
    KModal,
    KTable,
  },

  data() {
    return {
      keys: [],
      sshReady: false,
      sshNewKey: '',
      sshNewKeyTitle: '',
      sshTextDisabled: false,
      sshDisableDelete: false,
      confirmModal: false,
    };
  },

  beforeMount() {
    this.getSSHKeys();
  },

  computed: {
    validKey() {
      const re = /ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3}( [^@]+@[^@]+)?/;
      return re.test(this.sshNewKey);
    },
    sshbuttonDisabled() {
      return !this.validKey || this.sshTextDisabled;
    },
    kableHeaders() {
      return {
        id: {
          name: 'ID',
          type: 'string',
        },
        name: {
          name: 'Name',
          type: 'string',
        },
        dateCreated: {
          name: 'Date Created',
          type: 'date',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.keys.map((val, index) => ({
        dateCreated: val.created_at,
        id: val.id,
        name: val.title,
        delete: {
          key: index,
          text: getIcon({ color: 'var(--kate-danger-alt)', icon: 'trash' }),
        },
        ...val,
      }));
    },
  },

  methods: {
    deleteKey(keyId) {
      this.$logger.info('Deleting SSH key', { keyId }, true);
      this.sshDisableDelete = true;
      this.$http.delete(`/api/profile/ssh/keys/${keyId}`)
        .then(() => {
          this.$ktoast.success('Successfully removed key', { goAway: 2500 });
          this.$logger.info('Deleted SSH key', { keyId }, true);
          this.$nextTick(() => {
            this.getSSHKeys();
          });
        })
        .catch(err => {
          this.$logger.info('Error deleting SSH key', { keyId }, err);
          this.$ktoast.error(`Error deleting key: ${err.response.data}`, { goAway: 2500 });
        })
        .then(() => {
          this.sshDisableDelete = false;
        });
    },

    confirmDeletion() {
      this.deleteKey(this.toDeleteKey.id);
      this.toDeleteKey.id = undefined;
      this.confirmModal = false;
    },

    uploadNewKey() {
      this.$logger.info('Uploading new ssh key', undefined, true);
      this.sshTextDisabled = true;
      this.$http.post('/api/profile/ssh/keys', {
        key: this.sshNewKey,
        title: this.sshNewKeyTitle,
      })
        .then(() => {
          this.$ktoast.success('Success', { goAway: 2500 });
          this.$logger.info('Uploaded new ssh key', undefined, true);
          this.$nextTick(() => {
            this.getSSHKeys();
          });
        })
        .catch(err => {
          this.$logger.info('Error uploading new ssh key', undefined, err);
          this.$ktoast.error('Error adding key', { goAway: 2500 });
        })
        .then(() => {
          this.sshNewKey = '';
          this.sshNewKeyTitle = '';
          this.sshTextDisabled = false;
        });
    },

    getSSHKeys() {
      this.$logger.info('Getting ssh keys');
      this.sshReady = false;
      this.$http.get('/api/profile/ssh/keys')
        .then(response => {
          this.keys = response.data;
          this.$logger.info('Got ssh keys');
        })
        .catch(err => {
          this.$logger.info('Error getting ssh keys', undefined, err);
          console.log(err); // eslint-disable-line no-console
        })
        .then(() => {
          this.sshReady = true;
          this.$emit('sshReady');
        });
    },

    openConfirmModal(key) {
      this.toDeleteKey = key;
      this.confirmModal = true;
    },

    closeConfirmModal() {
      this.confirmModal = false;
    },

    tableCallback(key, index, row, column) {
      if (column === 'delete') {
        this.openConfirmModal(row);
      }
    },
  },
};
</script>

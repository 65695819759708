<template>
  <div class="feedback-block-content">
    <div class="panel panel-default feedback-evaluation">
      <feedback-header
        :title="title"
        :icon-class="iconClass"
        :display-scoring-indicator="displayScoringIndicator"
        :successes="filteredSuccesses.length"
        :failures="filteredFailures.length"
        :total="filteredResult.length"
        :filter-string="filterString"
        @clear-filter="clearFilter"
      />

      <div class="feedback-content" v-if="refinedResult.length">
        <feedback-entry
          v-for="(entry, index) in paginatedEntries"
          :key="entry.test_name + index"
          :entry="entry"
          :display-runner-keys="displayRunnerKeys"
          :display-result-types="displayResultTypes"
          :display-description="displayDescription"
          :show-explanations="!isDashboard"
          @open-details="openFunctionDetails(index)"
          @explain="openFunctionDetails(index, true)"
        />

        <pagination class="feedback-block-pagination" v-if="numOfPages !== undefined"
          v-model="currentPage"
          :max="10"
          :count="numOfPages"
          :total-entries="resultEntries.length"
          :displayed-entries="paginatedEntries.length">
        </pagination>

        <feedback-modal
          v-if="currentEntry"
          :show="openModal"
          :entry="currentEntry"
          :notebook="notebook"
          :display-description="displayDescription"
          :show-navigation="refinedResult.length > 1"
          :is-explaining="isExplaining"
          :explanation="explanation"
          :explanation-error="explanationError"
          :explanation-rating="explanationRating"
          :is-rating-disabled="isRatingDisabled"
          :show-explanations="!isDashboard"
          @close="closeFunctionDetails"
          @previous="decrementIndex"
          @next="incrementIndex"
          @explain="explainFeedback"
          @update-explanation-rating="updateExplanationRating"
        />
      </div>
      <div class="good-style-container" v-else>
        <p class="good-style">{{allGoodMessage}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.feedback-block-content {
  display: block;
  width: 100%;
}

.feedback-evaluation {
  display: block;
  width: 100%;
  padding: 15px;
}

.feedback-block-content .feedback-evaluation .feedback-content {
  position: relative;
  padding-bottom: 15px;
}

.feedback-block-pagination.panel-pagination.row {
  margin-top: 15px;
}

.good-style {
  color: var(--kate-type-success);
  font-size: 30px;
  font-weight: bold;
  display: block;
  text-align: center;
  margin: 10px 0;
}
</style>

<script>
import useFeedbackExplanationsStore from '../stores/feedback-explanations';
import FeedbackHeader from './components/feedback-header.vue';
import FeedbackEntry from './components/feedback-entry.vue';
import FeedbackModal from './components/feedback-modal.vue';
import Pagination from '../components/k-pagination.vue';
import useGlobalStore from '../stores/global';

export default {
  name: 'FeedbackBlock',

  components: {
    FeedbackHeader,
    FeedbackEntry,
    FeedbackModal,
    Pagination,
  },

  props: {
    submissionId: Number,
    resultEntries: Array,
    layoutObject: Object,
    filePath: String,
    functionTestedName: String,
    ignoreSuccess: {
      type: Boolean,
      default: true,
    },
    maxItemsPerPage: {
      type: Number,
      default: 8,
    },
    title: String,
    iconClass: String,
    notebook: {
      type: Object,
    },
    displayRunnerKeys: {
      type: Boolean,
      default: false,
    },
    displayResultTypes: {
      type: Boolean,
      default: true,
    },
    displayDescription: {
      type: Boolean,
      default: false,
    },
    displayScoringIndicator: {
      type: Boolean,
      default: true,
    },
    allGoodMessage: {
      type: String,
      default: 'All Good!',
    },
    sortPriority: {
      type: Array,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      currentPage: 0,
      currentIndex: 0,
      openModal: false,
      isExplaining: false,
      explanationError: null,
      explanation: null,
      explanationRating: null,
      isRatingDisabled: false,
      feedbackExplanationId: null,
    };
  },

  computed: {
    filterString() {
      if (!this.filePath) {
        return null;
      }
      return `${this.filePath}${this.functionTestedName ? `: ${this.functionTestedName}` : ''}`;
    },
    filterKeys() {
      return [this.filePath, this.functionTestedName];
    },
    currentEntry() {
      return this.refinedResult[this.currentIndex];
    },
    entriesAssociatedWithFiles() {
      return this.resultEntries.filter(x => x.file_path);
    },
    entriesNotAssociatedWithFiles() {
      return this.resultEntries.filter(x => !x.file_path);
    },
    filteredResult() {
      if (this.entriesNotAssociatedWithFiles.length === this.resultEntries.length) {
        return this.resultEntries;
      }
      const filteredEntries = this.entriesAssociatedWithFiles.filter(
        x => (this.filePath === undefined || x.file_path === this.filePath)
          && (this.functionTestedName === undefined
            || (x.function_tested__name || x.value_tested) === this.functionTestedName),
      );
      return this.entriesNotAssociatedWithFiles.concat(filteredEntries);
    },
    refinedResult() {
      // First get all results excluding NotImplemented and Timeout
      const results = this.filteredResult.filter(
        x => (!this.ignoreSuccess || x.result_type !== 'success')
          && !this.checkResultIsNotImplemented(x)
          && !this.checkResultIsTimeout(x),
      );

      // Group file errors by their error message
      const fileErrors = new Map();
      results.forEach(result => {
        if (result.result_type === 'error') {
          const errorKey = `${result.details || ''}${result.info || ''}`;
          if (!fileErrors.has(errorKey)) {
            fileErrors.set(errorKey, {
              ...result,
              test_name: 'file_error',
              test_description: 'Code execution error affecting feedback generation.',
              original_entries: [result],
            });
          } else {
            fileErrors.get(errorKey).original_entries.push(result);
          }
        }
      });

      const nonErrorResults = results.filter(x => x.result_type !== 'error');
      const consolidatedErrors = Array.from(fileErrors.values());

      return [...nonErrorResults, ...consolidatedErrors]
        .concat(this.filteredResult.filter(x => (!this.ignoreSuccess || x.result_type !== 'success')
          && (this.checkResultIsNotImplemented(x) || this.checkResultIsTimeout(x))))
        .sort(this.resultSortFunction);
    },
    numOfPages() {
      if (this.refinedResult.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.refinedResult.length / this.maxItemsPerPage);
    },
    paginatedEntries() {
      if (!this.numOfPages) {
        return this.refinedResult;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.refinedResult.length);
      return this.refinedResult.slice(startingIndex, endingIndex);
    },
    filteredFailures() {
      return this.filteredResult.filter(x => (!this.ignoreSuccess || x.result_type !== 'success'));
    },
    filteredSuccesses() {
      return this.filteredResult.filter(item => item.result_type === 'success');
    },
    modulePakId() {
      return parseInt(this.$route.params.modulePakId, 10);
    },
    isDashboard() {
      return this.store.isDashboard;
    },
  },

  watch: {
    filterKeys() {
      this.currentIndex = 0;
    },
    numOfPages() {
      this.currentPage = 0;
    },
    resultEntries() {
      this.currentIndex = 0;
    },
  },

  mounted() {
    this.preloadFeedbackExplanations();
  },

  methods: {
    checkResultIsNotImplemented(res) {
      if (res.details) {
        return res.details === 'Not Implemented' || res.details.includes('NotImplementedError');
      }
      return false;
    },
    checkResultIsTimeout(res) {
      if (res.details) {
        return res.details.slice(0, 16) === 'TestTimeoutError' || res.details.slice(0, 12) === 'TimeoutError';
      }
      return false;
    },
    resultSortFunction(a, b) {
      if (!this.sortPriority) {
        return 0;
      }
      const getResultVal = x => this.sortPriority
        .indexOf(`${x.file_path}:${x.function_tested__name || x.value_tested}`);

      return getResultVal(a) - getResultVal(b);
    },
    clearFilter() {
      this.$emit('clearfilter');
    },
    incrementIndex() {
      this.currentIndex = (this.currentIndex + 1) % (this.refinedResult.length);
      this.resetExplanationState();
    },
    decrementIndex() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.refinedResult.length - 1;
      } else {
        this.currentIndex -= 1;
      }
      this.resetExplanationState();
    },
    openFunctionDetails(index, initialShouldExplain = false) {
      let startingIndex;
      if (this.numOfPages) {
        startingIndex = this.maxItemsPerPage * this.currentPage;
      } else {
        startingIndex = 0;
      }
      this.currentIndex = index + startingIndex;
      this.openModal = true;

      this.resetExplanationState();

      const store = useFeedbackExplanationsStore();
      const currentEntry = this.refinedResult[this.currentIndex];
      let shouldExplain = initialShouldExplain;
      if (currentEntry) {
        const testName = currentEntry.test_name || 'file_error';
        if (testName && store.hasExplanation(this.modulePakId, testName)) {
          shouldExplain = true;
        }
      }

      if (shouldExplain && !this.explanation) {
        this.explainFeedback(this.currentEntry);
      }
    },
    closeFunctionDetails() {
      this.openModal = false;
      this.explanation = null;
      this.explanationError = null;
      this.isExplaining = false;
    },
    resetExplanationState() {
      const store = useFeedbackExplanationsStore();
      if (this.currentEntry) {
        const testName = this.currentEntry.test_name;
        const storedData = store.getExplanation(this.modulePakId, testName);

        if (storedData) {
          this.explanation = storedData.explanation;
          this.feedbackExplanationId = storedData.feedbackExplanationId;
          this.explanationRating = storedData.userRating;
          this.isRatingDisabled = storedData.userRating !== null;
          return;
        }
      }

      this.explanation = null;
      this.explanationError = null;
      this.isExplaining = false;
      this.feedbackExplanationId = null;
      this.explanationRating = null;
      this.isRatingDisabled = false;
    },
    async preloadFeedbackExplanations() {
      if (!this.submissionId) {
        return;
      }

      try {
        const response = await this.$http.get(`/api/chatbot/explain_feedback/${this.submissionId}`);
        const store = useFeedbackExplanationsStore();

        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(item => {
            const testName = item.test_name || 'file_error';
            store.setExplanation(
              this.modulePakId,
              testName,
              item.explanation,
              item.id,
              item.user_rating,
            );
          });
        }
      } catch (error) {
        this.$logger.autowarn('Failed to preload feedback explanations:', { submissionId: this.submissionId }, error);
      }
    },
    async explainFeedback(entry) {
      this.isExplaining = true;
      this.explanation = null;
      this.explanationError = null;
      this.feedbackExplanationId = null;
      this.explanationRating = null;
      this.isRatingDisabled = false;

      const store = useFeedbackExplanationsStore();
      const testName = entry.test_name;
      const cachedData = store.getExplanation(this.modulePakId, testName);

      if (cachedData) {
        this.explanation = cachedData.explanation;
        this.feedbackExplanationId = cachedData.feedbackExplanationId;
        this.explanationRating = cachedData.userRating;
        this.isRatingDisabled = cachedData.userRating !== null;
        this.isExplaining = false;
        return;
      }

      const testResult = {
        test_name: entry.test_name,
        test_description: entry.test_description,
        value_tested: entry.value_tested || '',
        info: entry.info || '',
        details: entry.details || '',
        cell_number: entry.cell_number || -1,
        line_number: entry.line_number || -1,
        cell_contents: entry.cell_contents || '',
      };

      try {
        const response = await this.$http.post('/api/chatbot/explain_feedback', {
          module_pak_id: this.modulePakId,
          submission_id: this.submissionId,
          test_result: testResult,
        });

        this.explanation = response.data.explanation;
        this.feedbackExplanationId = response.data.feedback_explanation_id;
        store.setExplanation(
          this.modulePakId,
          testName,
          response.data.explanation,
          response.data.feedback_explanation_id,
          null,
        );
      } catch (error) {
        this.explanationError = 'Failed to get explanation. Please try again.';
        this.$logger.error('Failed to get feedback explanation:', error);
      } finally {
        this.isExplaining = false;
      }
    },
    async updateExplanationRating(value) {
      if (this.isRatingDisabled) return;

      this.isRatingDisabled = true;
      try {
        await this.$http.put('/api/chatbot/explain_feedback/rating', {
          feedback_explanation_id: this.feedbackExplanationId,
          user_rating: value,
        });

        this.$ktoast.success('Thank you for your feedback!', { goAway: 3500 });
        this.explanationRating = value;

        const store = useFeedbackExplanationsStore();
        const testName = this.currentEntry.test_name || 'file_error';
        const storedData = store.getExplanation(this.modulePakId, testName);
        if (storedData) {
          store.setExplanation(
            this.modulePakId,
            testName,
            storedData.explanation,
            storedData.feedbackExplanationId,
            value,
          );
        }
      } catch (error) {
        this.$ktoast.error('Failed to submit rating. Please try again.', { goAway: 3500 });
        this.isRatingDisabled = false;
        this.$logger.error('Failed to rate explanation:', error);
      }
    },
  },
};
</script>

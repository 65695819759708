<template>
  <div>
    <k-modal class="k-thread-edit-modal" @close="close" :show="show">
      <template #header>
        <h3>Edit Post</h3>
      </template>
      <template #body>
        <div class="k-thread-edit-body-text">
          <form class="help-ticket-form" enctype="multipart/form-data">
            <fieldset class="form-entry" v-if="thread.is_question"><!-- // jscpd:ignore-start -->
              <label for="thread-title-input">Title:</label><br>
              <div class="form-info">
                <span>Please keep it short and specific</span>
              </div>
              <input id="thread-title-input" v-model="title" maxlength="100" placeholder="Enter title here..." class="form-input k-issue-text">
            </fieldset>

            <fieldset class="form-entry">
              <label for="editTextEditor">Body:</label><br>
              <div class="form-info">
                <span>Please include all the information someone would need to answer your question.</span><br>
              </div>
              <k-text-editor
                id="editTextEditor"
                v-model="body"
                @pasteResources="pasteResources"
              ></k-text-editor>
            </fieldset><!-- // jscpd:ignore-end -->
          </form>

          <div class="form-entry">
            <template v-if="existingResources.length > 0">
              <label for="existing-file-list">Existing Resources:</label><br>
              <k-file-list id="existing-file-list" :files="existingResources" @remove-file="removeExistingResource"></k-file-list>
            </template>
            <button title="Upload resources" class="btn btn-primary" @click="openUploadDialog">
              Upload Resources
              <i title="Upload resources" class="fas fa-upload clickable"></i>
            </button><br><br>
            <label for="new-resources-input" v-if="resources.length > 0">New Resources:</label><br><!-- // jscpd:ignore-start -->
            <input id="new-resources-input"
              type="file"
              name="files"
              style="display: none;"
              ref="filesInput"
              :accept="acceptedResources"
              @change="filesChange($event.target.files)" multiple>
            <k-file-list v-if="resources.length > 0" :files="resources" @remove-file="removeResource"></k-file-list>
          </div>
        </div><!-- // jscpd:ignore-end -->
      </template>
      <template #footer>
        <button @click="close" class="btn btn-default cancel-btn">Cancel</button>
        <button @click="openDeleteModal" class="btn btn-danger delete-btn">Delete</button>
        <button @click="editThread" :disabled="disableSend" class="btn btn-success send-btn">
          <i class="fa fa-spinner fa-spin" v-if="editInProgress"></i> Update Post</button>
      </template>
    </k-modal>
    <k-modal :show="showDeleteModal" @close="closeDeleteModal" class="view-programme-confirm-delete-modal">
        <template #header>
          <h3>Confirm deleting post</h3>
        </template>
        <template #body>
          <div class="view-programme-confirm-modal-body">
            <p>Are you sure you want to delete this post?</p>
            <p>This action cannot be undone.</p>
          </div>
        </template>
        <template #footer>
          <button class="modal-return-button btn btn-default cancel-btn" @click="closeDeleteModal">Cancel </button>
          <button v-if="deletingPost" class="btn btn-danger modal-delete-button" disabled>
            Deleting Post <i class="fas fa-spinner fa-spin"></i>
          </button>
          <button v-else class="btn btn-danger modal-delete-button" @click="deleteThread()">
            Delete Post
          </button>
        </template>
      </k-modal>
    <!-- // jscpd:ignore-start -->
    <k-modal :show="showEditModal" @close="closeEditModal" class="view-programme-confirm-edit-modal">
        <template #header>
          <h3>You have unsaved edits</h3>
        </template>
        <template #body>
          <div class="view-programme-confirm-modal-body">
            <p>Are you sure you want to stop editing this post?</p>
            <p>Your changes will not be saved.</p>
          </div>
        </template>
        <template #footer>
          <button class="modal-return-button btn btn-default cancel-btn" @click="closeEditModal">Cancel </button>
          <button class="btn btn-danger modal-delete-button" @click="discardChanges()">
            Discard Changes
          </button>
        </template>
      </k-modal>
      <!-- // jscpd:ignore-end -->
  </div>
</template>

<style>
.k-thread-edit-modal .modal-body {
  margin-bottom: 0;
}

.k-thread-edit-modal .modal-container {
  max-height: 90vh;
  min-width: 50%;
}

@media (min-width: 901px) {
  .k-thread-edit-modal .modal-container {
    width: 50%;
  }
}
</style>

<style scoped>
.k-thread-edit-body-text {
  display: block;
}

.k-issue-text {
  width: 100%;
}

.form-entry {
  margin-bottom: 15px;
}

.form-entry:last-child {
  margin-bottom: 0;
}

.form-entry input,
.form-entry textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 0;
  margin: 5px 0;
}

.form-info {
  color: var(--kate-type-primary);
  min-height: unset;
}

.form-entry textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
}

.form-entry ul {
  overflow: hidden;
}

.k-thread-edit-body-buttons {
  text-align: center;
}

.cancel-btn {
  float: left;
}

.btn-default {
  background-color: transparent;
  border: var(--border-primary);
  color: var(--kate-type-light);
}

.btn-default:hover {
  background-color: var(--kate-button-outlined-hover);
  border: var(--kate-button-outlined-hover-border);
  color: var(--kate-type-dark);
}
</style>

<script>
import useGlobalStore from '../stores/global';
import KModal from '../components/k-modal.vue';
import ErrorMixin from '../mixins/error-mixins';
import TimeMixin from '../mixins/time-mixins';
import ResourceMixin from '../mixins/resource-mixins';
import KFileList from '../components/k-file-list.vue';
import KTextEditor from '../components/k-text-editor.vue';

export default {
  mixins: [ErrorMixin, TimeMixin, ResourceMixin],

  components: {
    'k-modal': KModal,
    'k-file-list': KFileList,
    'k-text-editor': KTextEditor,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    thread: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      loading: false,
      editInProgress: false,
      isEdited: false,
      title: '',
      body: '',
      existingResources: [],
      resources: [],
      resourcesToDelete: [],
      showDeleteModal: false,
      showEditModal: false,
      trackChanges: false,
      deletingPost: false,
    };
  },

  watch: {
    thread() {
      this.title = this.thread.title;
      this.body = this.thread.body;
      this.existingResources = this.convertExistingResources(this.thread.resources);
      this.$nextTick(() => {
        if (this.show) {
          this.trackChanges = true;
        }
      });
    },

    resources() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    body() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },

    title() {
      if (this.trackChanges) {
        this.isEdited = true;
      }
    },
  },

  computed: {
    isDashboard() {
      return this.store.isDashboard;
    },

    isQuestion() {
      return this.thread.is_question;
    },

    validForm() {
      if (this.thread.is_question) {
        return Boolean(this.title && this.body);
      }
      return Boolean(this.body);
    },

    disableSend() {
      return !this.validForm || this.body.length > 10000;
    },
  },

  methods: {
    convertExistingResources(resources) {
      if (!resources) return [];
      return resources.map(x => ({
        src: x.resource_link,
        name: x.text || 'Image',
        type: 'image',
      }));
    },

    clearFields() {
      this.title = '';
      this.body = '';
      this.resources = [];
      this.trackChanges = false;
      this.isEdited = false;
    },

    close() {
      if (this.editInProgress) return;
      if (this.isEdited) {
        this.openEditModal();
      } else {
        this.clearFields();
        this.$emit('close');
      }
    },

    discardChanges() {
      this.isEdited = false;
      this.closeEditModal();
      this.close();
    },

    deleted() {
      this.$emit('deleted', { id: this.thread.id });
      this.$emit('close');
    },

    openUploadDialog() {
      this.$refs.filesInput.click();
    },

    removeExistingResource(payload) {
      this.$logger.info(`Flagging resource ${payload.file.name} for deletion`);
      this.isEdited = true;
      this.existingResources.splice(payload.index, 1);
      this.resourcesToDelete.push(this.thread.resources.find(x => x.resource_link === payload.file.src).id);
      this.$ktoast.success('Removed existing resource');
    },

    deleteResource(resourceId) {
      this.$logger.info(`Deleting resource ${resourceId}`);
      this.$http.delete(`/api/curriculum/knowledge/threads/${this.thread.id}/resources/${resourceId}`).then(() => {
        this.$logger.info('Deleted resource', undefined, true);
      }).catch(err => {
        this.$logger.error('Error deleting resource', { resourceId }, err);
        this.showError(err);
      });
    },

    deleteThread() {
      this.deletingPost = true;
      this.$logger.info('Deleting thread', undefined, true);
      this.$http.delete(`/api/curriculum/knowledge/threads/${this.thread.id}`).then(() => {
        this.$ktoast.success('Post has been deleted successfully');
        this.$logger.info('Deleted thread', { threadId: this.thread.id }, true);
        if (this.isQuestion) {
          if (this.isDashboard) {
            this.$router.push({
              name: 'knowledge_base_overview',
            });
          } else {
            this.$router.push({
              name: 'module_knowledge_base',
              query: {
                threadDeleted: true,
              },
            });
          }
        }
        this.deleted();
        this.closeDeleteModal();
        this.deletingPost = false;
      }).catch(err => {
        this.$logger.error('Error deleting thread', { threadId: this.thread.id }, err);
        this.showError(err);
      });
    },

    editThread() {
      this.$logger.info('Editing thread', undefined, true);
      this.resourcesToDelete.map(x => this.deleteResource(x));
      this.editInProgress = true;
      this.getEditPayload().then(payload => {
        this.$http.put(`/api/curriculum/knowledge/threads/${this.thread.id}`, payload).then(() => {
          this.$ktoast.success('Post has been edited successfully');
          this.$logger.info('Edited thread', { threadId: this.thread.id });
        }).catch(err => {
          this.$logger.error('Error editing thread', { threadId: this.thread.id }, err);
          this.showError(err);
        }).then(() => {
          this.editInProgress = false;
          this.isEdited = false;
          this.close();
          this.$emit('edited');
        });
      });
    },

    getEditPayload() {
      if (this.thread.is_question) {
        return this.formatResourcesForUpload().then(formattedResources => ({
          title: this.title,
          body: this.body,
          resources: formattedResources,
        }));
      }
      return this.formatResourcesForUpload().then(formattedResources => ({
        body: this.body,
        resources: formattedResources,
      }));
    },

    openDeleteModal() {
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
    },

    openEditModal() {
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },
  },
};
</script>

<template>
  <div v-if="ready" class="content-with-spacing">
    <section class="row profile-info">
      <h2><i class="fas fa-address-card"></i> Profile Information</h2>
      <account-info :user-info="userInfo" v-if="userInfo" @update="getUserInfo"></account-info>
    </section>
  </div>
</template>

<script>
import AccountInfoSettings from './account-info-settings.vue';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';

export default {
  components: {
    'account-info': AccountInfoSettings,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  data() {
    return {
      userInfo: {},
      ready: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      this.$logger.info('Getting profile info');
      this.ready = false;
      this.$http.get('/api/profile/user')
        .then(response => {
          this.$logger.info('Got profile info', undefined);
          this.userInfo = response.data;
        })
        .catch(err => {
          this.$logger.error('Error getting user info', undefined, err);
          this.showError(err, true);
        })
        .then(() => {
          this.ready = true;
        });
    },
  },
};
</script>

<template>
  <div id="notebook-render" class="read-only">
    <div v-for="(cell, index) in cells"  :key="`${notebookReady}-${index}`" class="notebook-cell-container" :ref="`cell_${index}`" :class="{ 'highlighted' : highlightCell(index) }">
      <div v-if="cell.cell_type === 'markdown'" class="notebook-render-markdown notebook-cell">
        <div class="notebook-cell-left"></div>
        <div v-html="getMarkdown(cell.source)" class="notebook-cell-right"></div>
      </div>
      <div v-else-if="cell.cell_type === 'code'" class="notebook-code-cell">
        <div class="notebook-code-cell-left notebook-input-label">
          <!-- <p>In [{{cell.execution_count}}]:</p> -->
        </div>
        <k-editor v-if="cell.source"
          @click="emitClick()"
          class="notebook-cell notebook-code-cell-right"
          :read-only="true"
          syntax="python"
          :line-numbers="false"
          :theme="this.themeStore.theme !== 'light' ? 'dark' : 'light'"
          :text="nbToHtml(cell.source)"></k-editor>

        <div class="notebook-code-cell-left notebook-output-label" v-if="cell.outputs.length">
          <!-- <p>Out [{{cell.execution_count}}]:</p> -->
        </div>
        <div class="notebook-cell notebook-code-cell-right notebook-render-output" v-if="cell.outputs">
          <div v-for="out in cell.outputs">
            <template v-if="out.data">
              <div v-if="out.data['text/html']" v-html="nbToHtml(out.data['text/html'])"></div>
              <div v-else-if="out.data['text/plain']">{{nbToHtml(out.data['text/plain'], '\n')}}</div>
              <div class="image-cell" v-if="out.data['image/png']"><img :src="`data:image/png;base64, ${out.data['image/png']}`" alt="Notebook image"/></div>
            </template>
            <div class="text-cell" v-if="out.text">
              {{nbToHtml(out.text, '\n')}}
            </div>
            <div class="error-cell" v-if="out.traceback">
              <pre>{{parseNotebookTraceback(out.traceback)}}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.notebook-cell-container.highlighted {
  border-left: 4px;
  border-right: 1px;
  border-top: 1px;
  border-bottom: 1px;
  border-color: var(--kate-4);
  border-style: solid;
}

.notebook-render-cell-number {
  padding-right: 2pt;
  font-size: smaller;
  border-top: 1px solid var(--kate-type-primary);
  border-right: 1px solid var(--kate-type-primary);
  margin-left: 4pt;
  color: var(--kate-type-primary);
  font-family: monospace;
}

#notebook-render .cm-cursor {
  display: none;
}

.notebook-code-cell-left p {
  text-align: right;
  font-family: monospace;
  white-space: nowrap;
}

.notebook-input-label p {
  color: var(--kate-primary-blue);
}

.notebook-output-label p {
  color: var(--kate-warning);
}

.notebook-code-cell,
.notebook-render-markdown {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-column-gap: 1em;
}

.notebook-cell {
  margin-bottom: 1em;
  min-width: 1px;
}

#notebook-render .cm-editor {
  height: auto;
  border: 1px solid var(--kate-type-primary);
}

.notebook-render-markdown p {
  color: var(--kate-type-white);
}

.notebook-render-markdown table {
  margin: 12px 0;
}

.notebook-render-markdown table tr td,
.notebook-render-markdown table th {
  padding: 8px 12px;
  border: 1px solid var(--kate-type-primary);
}

.notebook-render-markdown table th {
  background-color: var(--kate-background-body);
  border-bottom: solid 2px var(--kate-type-primary);
  font-weight: bold;
  color: var(--kate-type-white);
}

.notebook-render-markdown table tr:nth-child(2n) {
  background-color: var(--kate-panel-alt);
}

#notebook-render .image-cell img {
  background-color: var(--kate-type-white);
}
</style>

<script>
import useGlobalStore from '../stores/global';
import useThemeStore from '../stores/theme';
import { markdown } from '../modules/maths-markdown';
import KEditor from '../ide/k-editor.vue';

const TRACEBACK_REGEX = /\[[0-9]+(;[0-9]+)?m/gm;
const LINE_TERMINATOR_REGEX = /([\n\r]$|[\n\r][^\S\n\r]+$)/;

export default {
  components: {
    'k-editor': KEditor,
  },
  props: {
    showCellNumbers: {
      type: Boolean,
      default: false,
    },
    ipynb: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Number,
    },
  },
  data() {
    return {
      store: useGlobalStore(),
      themeStore: useThemeStore(),
      notebookRender: '',
    };
  },
  mounted() {
    if (!Number.isNaN(this.highlightedCell)) {
      this.$nextTick(() => {
        this.goToCell(this.highlightedCell);
      });
    }
  },
  computed: {
    highlightedCell() {
      if (!this.$route.query.highlight) {
        return NaN; // empty string resolves to 0
      }
      return Number(this.$route.query.highlight);
    },
    cells() {
      return this.ipynb.cells || [];
    },
    notebookReady() {
      return this.store.notebookReady;
    },
  },
  methods: {
    goToCell(index) {
      if (this.$refs[`cell_${index}`]) {
        const elementTop = this.$refs[`cell_${index}`][0].offsetTop;
        window.scrollTo(0, elementTop - 100); // give a little breathing room
      } else if (index < 0 || index >= this.cells.length) {
        this.$logger.warn('Attempted to highlight non-existent cell in notebook');
      }
    },
    highlightCell(index) {
      return this.highlightedCell === index;
    },
    nbToHtml(arr, sep) {
      if (arr && Array.isArray(arr)) {
        return arr.filter(x => x !== '\n').join(sep || '');
      }
      this.$logger.warn('Attempted to render badly-defined notebook cell');
      return '';
    },
    parseNotebookTraceback(tb) {
      const out = tb.join('\n').replace(TRACEBACK_REGEX, '');
      return out;
    },
    getMarkdown(source) {
      // Trim any whitespace at the end and remove one terminating \n or \r if there is one.
      // This \n or \r will then be replaced when we join the source together
      return markdown(source.map(x => x.replace(LINE_TERMINATOR_REGEX, '')).join('\n'));
    },
    emitClick() {
      this.$emit('clickevent');
    },
  },
};
</script>

<template>
  <div v-if="programmeModulesReady">
    <empty-placeholder v-if="emptyProgramme"
      title="No modules available in this programme"
      :info="emptyProgrammeText"
      :fa-icon="emptyIcon"
    ></empty-placeholder>
    <template v-else>
      <div class="filters-container">
        <div class="nav-tabs-wrapper">
          <ul class="nav nav-tabs">
            <!-- Always display curriculum tab -->
            <li class="tablink-container" :class="{ 'active': this.track==='curriculum', }">
              <router-link :to="{ name: 'programme_page', params: { programmeId: programmeId, track: 'curriculum' } }">Curriculum</router-link>
            </li>
            <li v-if="hasTrackModules('milestone')" class="tablink-container" :class="{ 'active': this.track==='milestone', }">
              <router-link :to="{ name: 'programme_page', params: { programmeId: programmeId, track: 'milestone' } }">Milestones</router-link>
            </li>
          </ul>
        </div>
        <div class="actions-container flex-this">
          <button class="btn btn-default" aria-label="Sort" title="Sort" @click="toggleSort"> Sort
            <i class="fas" :class="sortIcon"></i>
          </button>
          <button class="btn btn-outlined" aria-label="Toggle layout" title="Toggle layout" @click="toggleLayout">
            <i class="fa-solid" :class="setModulesLayout ? 'fa-list' : 'fa-grid-2'"></i>
          </button>
        </div>
      </div>
      <div class="module-cards-container" v-if="orderedModules.length > 0" :class="setModulesLayout ? 'grid-view': 'list-view'">
        <module-card
          v-for="mod in orderedModules"
          :key="mod.name"
          :layout="setModulesLayout ? 'grid-view': 'list-view'"
          :prog-mod="mod">
        </module-card>
      </div>
      <empty-placeholder v-else
        :title="emptyTrackTitle"
        :info="emptyTrackText"
        :fa-icon="emptyIcon"
      ></empty-placeholder>
    </template>
  </div>
</template>

<style>
.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  grid-column-gap: 15px;
}

.grid-view .panel-horizontal .module-content {
  gap: 15px;
  justify-content: flex-start;
}

.grid-view .module-body {
  flex-direction: column;
  text-align: left;
}

.grid-view .module-progress-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  column-gap: 15px;
  justify-content: space-between;
}

.grid-view .completion-date,
.grid-view .module-body,
.grid-view .module-progress-container {
  margin: 0;
  padding: 0;
}

.grid-view .k-progress {
  flex: 1 1 25%;
  min-width: 150px;
}

.grid-view .completion-date {
  flex: 1 1 70%;
  max-width: 340px;
}

@media (max-width: 842px) {
  .grid-view .module-button-container {
    text-align: center;
  }

  .grid-view .module-description {
    max-height: unset;
  }
}

@media (min-width: 544px) and (max-width: 670px) {
  .grid-view .module-progress-container {
    display: block;
  }

  .grid-view .k-progress {
    width: 100%;
    max-width: unset;
  }
}

@media (max-width: 768px) {
  .grid-view .k-progress {
    max-width: unset;
    width: 100%;
  }
}
</style>

<style scoped>
.actions-container {
  gap: 10px;
}

.filters-container {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters-container ul {
  float: left;
}

.nav-tabs-wrapper {
  margin: inherit;
}

.filters-container .btn-default {
  background-color: transparent;
  border: var(--border-primary);
  color: var(--kate-type-light);
}

.filters-container .btn-default:focus,
.filters-container .btn-default:hover {
  background-color: var(--kate-background-alt-alpha);
  border-color: var(--kate-background-alt-alpha);
}

</style>

<script>
import useGlobalStore from '../stores/global';
import ModuleCard from './module-card.vue';
import TimeMixin from '../mixins/time-mixins';
import ErrorMixin from '../mixins/error-mixins';
import StorageMixin from '../mixins/storage-mixins';
import EmptyPlaceholder from '../components/empty-placeholder.vue';

export default {
  props: {
    programmeId: {
      type: Number,
      required: true,
    },
    track: {
      type: String,
      required: true,
    },
  },

  components: {
    ModuleCard,
    EmptyPlaceholder,
  },

  mixins: [TimeMixin, ErrorMixin, StorageMixin],

  data() {
    return {
      programmeModulesReady: false,
      programmeModules: [],
      store: useGlobalStore(),
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeModules();
    // Sorting
    const sortingState = this.loadSetting('programme-content', 'sortingState');
    if (sortingState !== undefined) {
      this.store.setModulesSorting(sortingState);
    }
    // Layout
    const layoutState = this.loadSetting('programme-content', 'layoutState');
    if (layoutState !== undefined) {
      this.store.setModulesLayout(layoutState);
    }
  },

  watch: {
    programmeModulesReady() {
      if (this.programmeModulesReady) {
        this.$Loading.finish();
        this.$emit('modulesReady');
      }
    },
    programmeId() {
      this.$Loading.start();
      this.getProgrammeModules();
    },
  },

  computed: {
    programmeScheduleLink() {
      return `<a href="/schedule/programmes/${this.programmeId}">Schedule Page</a>`;
    },

    emptyProgramme() {
      return Boolean(this.programmeModules.length === 0 && this.programmeModulesReady);
    },

    orderedModules() {
      if (!this.sortDescending) {
        return this.programmeModules.slice().sort(this.sortModule).filter(m => m.track === this.track);
      }
      return this.programmeModules.slice().sort(this.sortModule).reverse().filter(m => m.track === this.track);
    },

    apiEndpoint() {
      return `/api/curriculum/programmes/${this.programmeId}/modules`;
    },

    sortDescending() {
      return this.store.sortModulesDescending;
    },

    setModulesLayout() {
      return this.store.isGridLayout;
    },

    emptyProgrammeText() {
      return `You can check the ${this.programmeScheduleLink} to see when each module will be released in this programme.
      <br>If you think there is an issue, please contact Cambridge Spark`;
    },

    emptyTrackTitle() {
      return `No modules available in the ${this.track} track`;
    },

    emptyTrackText() {
      return `You can check the ${this.programmeScheduleLink} to see when each module will be released in this programme.
      <br>To see modules in other tracks, select a different track from the tabs above`;
    },

    emptyIcon() {
      return 'fa-duotone fa-hourglass-start';
    },

    sortIcon() {
      return this.sortDescending ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-up';
    },
  },

  methods: {
    hasTrackModules(track) {
      return this.programmeModules?.filter(m => m.track === track).length > 0;
    },

    getProgrammeModules() {
      if (!this.programmeId) {
        return;
      }
      this.$logger.info('Getting modules for programme', {
        programmeId: this.programmeId,
      }, true);
      this.$http.get(this.apiEndpoint)
        .then(response => {
          this.programmeModules = response.data.modules;
          this.programmeModulesReady = true;
          this.$logger.info('Got modules for programme', {
            programmeId: this.programmeId,
          });
        })
        .catch(err => {
          this.programmeModules = [];
          if (this.$http.errIn(err, [404])) {
            this.$logger.warn('No modules found for programme', {
              programmeId: this.programmeId,
            });
            this.$ktoast.info('No modules were found for this programme', { position: 'top-center' });
          } else if (this.$http.isWarning(err)) {
            this.$logger.warn('Error while getting modules for programme', {
              programmeId: this.programmeId,
            }, err);
            this.showError(err, true);
          } else {
            this.$logger.error('Error while getting modules for programme', {
              programmeId: this.programmeId,
            }, err);
            this.showError(err, true);
          }
        })
        .then(() => {
          this.$Loading.finish();
          this.programmeModulesReady = true;
        });
    },

    // The sort function to rule them all
    sortModule(a, b) {
      if (a.number && b.number) {
        return this.sortByNumber(a, b);
      }
      if (!a.number && !b.number) {
        return this.sortByName(a, b);
      }
      if (a.number && !b.number) {
        return -1;
      }
      if (!a.number && b.number) {
        return 1;
      }
      return 0;
    },

    // Sort by name
    sortByName(a, b) {
      const alc = a.name;
      const blc = b.name;
      if (alc > blc) {
        return 1;
      }
      if (alc < blc) {
        return -1;
      }
      return 0;
    },

    // Sort by Number
    sortByNumber(a, b) {
      const numA = a.number;
      const numB = b.number;
      if (numA < numB) {
        return -1;
      }
      if (numA > numB) {
        return 1;
      }
      return 0;
    },

    toggleSort() {
      this.store.toggleModulesSortingOrder();
      this.saveSetting('programme-content', 'sortingState', this.sortDescending);
    },

    toggleLayout() {
      this.store.toggleModulesLayout();
      this.saveSetting('programme-content', 'layoutState', this.setModulesLayout);
    },
  },
};
</script>

<template>
  <div v-if="ready">
    <div class="content-with-spacing">
      <div class="heading-container">
        <h1 v-if="slidesTitle">{{ slidesTitle }}</h1>
        <div class="heading-buttons">
          <module-asset-completion-button :asset="asset"></module-asset-completion-button>
        </div>
      </div>
      <div v-if="slidesContentReady" class="slides-container panel panel-default">
        <iframe :data="signedUrl" type="application/pdf" :src="signedUrl" title="Slides display">
          <embed :src="signedUrl" type="application/pdf" />
        </iframe>
      </div>
    </div>
  </div>
</template>

<style scoped>
.heading-container {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.heading-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.slides-container {
  padding: 15px;
  height: 70vh;
}

.slides-container.panel.panel-default,
.slides-container.panel.panel-default iframe {
  display: flex;
  flex: 1;
}

.category-header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .slides-container.panel.panel-default iframe {
    width: 100%;
  }
}
</style>

<style>
#content .heading-container h1 {
  margin: 0;
}
</style>

<script>
import ModuleAssetCompletionButton from './module-asset-completion-button.vue';
import ErrorMixin from '../mixins/error-mixins';
import PageReadyMixin from '../mixins/page-ready-mixin';

export default {
  components: {
    ModuleAssetCompletionButton,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    asset: {
      type: Object,
    },
    sidebarReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      slidesContentReady: false,
      signedUrl: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getSignedUrl();
  },

  watch: {
    assetId() {
      this.slidesContentReady = false;
      this.getSignedUrl();
    },
  },

  computed: {
    ready() {
      return Boolean(this.asset && this.slidesContentReady && this.sidebarReady);
    },

    assetId() {
      return this.asset?.id;
    },

    slidesTitle() {
      return this.asset?.name;
    },
  },

  methods: {
    getSignedUrl() {
      if (this.assetId) {
        this.$logger.info('Getting slides signed URL and metadata', { slidesId: this.assetId }, true);
        this.$http.get(`/api/curriculum/slide_deck/${this.assetId}`)
          .then(result => {
            this.$logger.info('Got signed URL and metadata', { slidesId: this.assetId });
            this.signedUrl = result.data.slides_url;
          })
          .catch(err => {
            this.$logger.autowarn('Error getting signed URL and metadata', { slidesId: this.assetId }, err);
          })
          .then(() => {
            this.slidesContentReady = true;
          });
      }
    },
  },
};
</script>

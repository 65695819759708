import useGlobalStore from '../stores/global';

const DASHBOARD_PAGE_PERMISSIONS = {
  programmes_overview_current_programmes: ['manage_programmes'],
  programmes_overview_create_programme: ['manage_programmes'],
  programmes_overview: ['manage_programmes'],
  manage_programme: ['manage_programmes'],
  manage_programme_members: ['manage_programmes'],
  manage_programme_content: ['manage_programmes'],
  manage_module: ['manage_programmes'],
  users_privileged_users: ['manage_roles'],
  users_coach: ['manage_coaches'],
  users_trainer: ['manage_trainers'],
  users_data_mentor: ['manage_mentors'],
  curriculum_blueprints_programmes: ['manage_blueprints'],
  curriculum_manage_programme_blueprint: ['manage_blueprints'],
  curriculum_blueprints_modules: ['manage_blueprints'],
  manage_module_blueprint: ['manage_blueprints'],
  manage_programme_blueprint_module: ['manage_blueprints'],
  curriculum_assets_lu: ['manage_assets', 'view_assets'],
  curriculum_assets_pak: ['manage_assets', 'view_assets'],
  curriculum_assets_slides: ['manage_assets'],
  curriculum_assets_video: ['manage_assets'],
  curriculum_assets_quiz: ['manage_assets'],
  curriculum_assets_calendar_event: ['manage_assets'],
  curriculum_assets_meeting: ['manage_assets'],
  curriculum_assets_markdown_page: ['manage_assets'],
  curriculum_assets_questionnaire: ['manage_assets'],
  curriculum_asset_details: ['manage_assets', 'view_assets'],
  edit_quiz: ['manage_assets'],
  edit_questionnaire: ['manage_assets'],
  knowledge_base_overview: ['view_knowledge_base'],
  knowledge_thread: ['view_knowledge_base'],
  knowledge_detached_thread: ['view_knowledge_base'],
  monitor_engagement_overview: ['monitor_programmes'],
  monitor_learner_progress: ['monitor_programmes'],
  employer_learner_summary: ['view_employer_learners'],
  manage_achievements: ['manage_achievements'],
};

class PermissionsHandler {
  constructor() {
    this.store = useGlobalStore();
  }

  hasPermission(permission) {
    if (this.store && this.store.userPermissions) {
      return this.store.userPermissions.includes(permission);
    }
    return false;
  }

  hasDashboardPagePermission(pageName) {
    const pagePerm = DASHBOARD_PAGE_PERMISSIONS[pageName];
    if (!pagePerm) {
      return true;
    }
    if (this.store && this.store.userPermissions) {
      return this.store.userPermissions.some(perm => pagePerm.includes(perm));
    }
    return false;
  }
}

export default {
  install(app) {
    const handler = new PermissionsHandler();
    app.config.globalProperties.$permissions = handler; // eslint-disable-line no-param-reassign
  },
};

<template>
  <div class="module-panel panel">
    <div class="panel-body">
      <div class="heading-container">
        <div class="heading-title">
          <h2 class="question-container">
            <span class="question-number">{{ question.number }}. </span>
            <span :class="answerClass" class="question" v-html="getMarkdown(question.question)"></span>
          </h2>
          <p v-if="question.select_all_that_apply" class="question-instruction">Select all that apply </p>
        </div>
        <div v-if="showResult" class="question-result">
          <span class="correct" v-if="correct"><i class="fas fa-check"></i></span>
          <span class="wrong" v-else><i class="fas fa-times"></i></span>
        </div>
        <div v-else>
          <div v-if="question.hint" class="question-icon">
            <k-tooltip :text="question.hint">
              <span>Hint <i class="fas fa-question-circle"></i></span>
            </k-tooltip>
          </div>
        </div>
      </div>
      <div v-if="question.resources && question.resources.length > 0" class="row question-resources">
        <k-gallery v-if="imageResources.length > 0" :images="imageResources"></k-gallery>
      </div>
      <ul class="responses-list" :class="{'read-only': readOnly || showResult }">
        <template v-if="showResult && this.previousAnswer">
          <li v-for="ans in question.answers" :key="ans.id">
            <input v-if="getInputType(question) === 'radio'" class="answer" readonly
              :id="ans.id" type="radio"
              :name="question.id"
              :value="previousAnswer[0]"
              :checked="previousAnswer[0] === ans.id"
              >

            <input v-else class="answer" :id="ans.id" :type="getInputType(question)"
              :name="question.id"
              :value="previousAnswer"
              :checked="previousAnswer.indexOf(ans.id) > -1"
              >

            <label :for="ans.id">{{ ans.answer }}</label>
            <div class="check" :class="getInputType(question)"><div class="inside"></div></div>
          </li>
        </template>

        <template v-else>
          <li v-for="ans in question.answers" :key="ans.id">
            <input v-if="getInputType(question) === 'radio'" class="answer"
              v-model="currentAnswer[0]"
              :id="ans.id" type="radio"
              :name="question.id"
              :value="ans.id">

            <input v-else class="answer" :id="ans.id" :type="getInputType(question)"
              v-model="currentAnswer"
              :name="question.id"
              :value="ans.id"> <!-- // jscpd:ignore-start -->
            <label :for="ans.id">{{ ans.answer }}</label>
            <div class="check" :class="getInputType(question)"><div class="inside"></div></div>
          </li>
        </template>
      </ul> <!-- // jscpd:ignore-end -->
    </div>
  </div>
</template>

<style>
.question-container span p {
  color: var(--kate-type-light);
}
</style>

<style scoped>
.heading-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.question-container {
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.35em;
}

.question-number {
  padding-right: 5px;
  min-width: 30px;
}

.question {
  width: 95%;
}

.question-container span p,
.question-instruction {
  color: var(--kate-type-light);
}

.question-result {
  padding: 20px 10px 10px;
}

.question-result span {
  font-size: 1.25em;
}

.heading-title h2.correct {
  border-bottom: 2px solid var(--kate-success);
  color: var(--kate-success-alt);
}

.question-result span.correct {
  color: var(--kate-success);
}

.heading-title h2.wrong {
  border-bottom: 2px solid var(--kate-danger);
  color: var(--kate-danger-light);
}

.question-result span.wrong {
  color: var(--kate-danger);
}

.question-resources {
  margin-bottom: 15px;
  padding: 0;
}

.responses-list {
  list-style: none;
  padding-left: 0;
}

.responses-list li {
  position: relative;
  color: var(--kate-type-primary);
  width: fit-content;
}

.responses-list li label {
  padding-left: 35px;
}

/* Read Only */
.read-only {
  pointer-events: none;
}

/* Info Icon and Tooltip */
.question-icon {
  position: relative;
  display: inline-block;
  padding: 2px 0;
  margin: 15px 0;
  cursor: pointer;
  min-width: 50px;
}

.question-icon i {
  transition: all 0.3s;
  color: var(--kate-type-blue);
  padding: 5px 0;
}

.question-icon:hover i {
  color: var(--kate-secondary);
}

@media screen and (max-width: 479px) {
  .category-text {
    position: relative;
  }

  .question-icon {
    position: unset;
  }
}
</style>

<script>
import { markdown } from '../modules/maths-markdown';
import KTooltip from '../components/k-tooltip.vue';
import KGallery from '../components/k-gallery.vue';

export default {
  components: {
    KTooltip,
    KGallery,
  },

  props: {
    question: {
      type: Object,
    },
    previousAnswer: {
      type: Array,
      default: () => [],
    },
    correct: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showResult: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentAnswer: [],
    };
  },

  watch: {
    currentAnswer() {
      this.emitAnswer();
    },
  },

  created() {
    this.currentAnswer = this.previousAnswer;
  },

  computed: {
    answerClass() {
      if (!this.showResult) {
        return '';
      }
      if (this.correct) {
        return 'correct';
      }
      return 'wrong';
    },

    imageResources() {
      return this.question.resources.filter(x => x.resource_type === 'image').map(x => x.resource_link);
    },
  },

  methods: {
    getInputType(question) {
      if (question.select_all_that_apply) {
        return 'checkbox';
      }
      return 'radio';
    },

    emitAnswer() {
      this.$emit('update', this.currentAnswer);
    },

    clearAnswer() {
      this.currentAnswer = [];
    },

    getMarkdown(str) {
      return markdown(str);
    },

  },
};
</script>

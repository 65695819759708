<template>
  <div v-if="message" class="message" :class="[message.fromChatbot ? '' : 'alt-text', showActions ? 'margin-bottom' : '']" >
    <img v-if="message.fromChatbot" :src="chatbotImage" class="chatbot-icon" alt="KATE chat bot image"/>
    <div class="message-content" :class="loading ? 'loader-spacing' : ''">
      <typing-loader v-if="loading"></typing-loader>
      <div v-else>
        <div v-html="getMarkdown(message.text)"></div>
        <div v-if="message.source_documents?.length" class="sources-container">
          <span>Sources:</span>
          <ul v-for="source in message.source_urls" :key="source.url">
            <li><a :href="source.url" target="_blank">{{ source.title }}</a></li>
          </ul>
        </div>
        <div v-if="message.fromChatbot && showActions" class="rating-container">
          <button class="upvote"
              @click="updateRating(true)"
              :disabled="isDisabled"
              :class="rating ? 'upvoted': ''"
              aria-label="Upvote button"
              title="Upvote button">
            <i class="fas fa-thumbs-up"></i>
          </button>
          <button class="downvote"
              @click="updateRating(false)"
              :disabled="isDisabled"
              :class="rating == false ? 'downvoted': ''"
              aria-label="downvote button"
              title="downvote button">
            <i class="fas fa-thumbs-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.message-content p {
  margin-bottom: 0;
}
</style>

<style scoped>
h5 {
  margin: 0 0 0.5rem;
}

.message.alt-text.right,
.message {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  gap: 5px;
}

.message.alt-text {
  flex-direction: row-reverse;
}

.message.margin-bottom {
  margin-bottom: 35px;
}

.message .message-content {
  background: var(--kate-chat);
  padding: 15px 15px 25px;
  width: fit-content;
  border-radius: 20px 20px 20px 0;
  border: var(--kate-chat-alt-border);
  position: relative;
}

.message .message-content.loader-spacing {
  padding: 15px;
}

.message.alt-text .message-content {
  background: var(--kate-chat-alt);
  border-radius: 20px 20px 0;
}

.message:first-child .message-content,
.message.alt-text .message-content {
  padding: 15px;
}

.message .chatbot-icon {
  width: 40px;
}

.rating-container {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 0;
  bottom: -17px;
}

.rating-container button {
  background-color: var(--kate-background);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: 1px solid;
  padding: 6px;
}

.rating-container button.upvote {
  color: var(--kate-success-alt);
  border-color: var(--kate-success-alt);
}

.rating-container button.upvote:hover,
.rating-container button.upvote.upvoted,
.rating-container button.upvote.upvoted:hover {
  color: var(--kate-type-success);
  border-color: var(--kate-type-success);
}

.rating-container button.downvote {
  color: var(--kate-danger-alt);
  border-color: var(--kate-danger-alt);
}

.rating-container button.downvote:hover,
.rating-container button.downvote.downvoted,
.rating-container button.downvote.downvoted:hover {
  color: var(--kate-danger);
  border-color: var(--kate-danger);
}

.rating-container button:disabled,
.rating-container button:disabled:hover {
  color: var(--kate-disabled);
  border-color: var(--kate-disabled);
  background-color: var(--kate-background);
}

.sources-container {
  margin: 16px 0 0;
  font-size: 0.85em;
}

.sources-container span {
  font-style: italic;
}

.sources-container ul {
  padding-left: 15px;
}

.sources-container ul li {
  word-break: break-word;
}
</style>

<script>
import { importImage } from '../../modules/dynamic-image-importer';
import TypingLoader from './typing-loader.vue';
import { markdown } from '../../modules/maths-markdown';

export default {
  components: {
    TypingLoader,
  },
  props: {
    message: {
      type: Object,
    },
    loading: Boolean,
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rating: null,
      isDisabled: false,
    };
  },

  computed: {
    chatbotImage() {
      return importImage('icons/kate-bot-chat-colours.svg');
    },
  },

  methods: {
    updateRating(value) {
      const jsonPayload = {
        chatbot_query_feedback_id: this.message?.chatbot_query_feedback_id,
        user_rating: value,
      };
      this.$logger.info('Updating feedback rating', undefined, true);
      this.$http.put('/api/chatbot/query/feedback', jsonPayload)
        .then(() => {
          this.$ktoast.success('Thank you for your feedback!', { goAway: 3500 });
          this.$logger.info('Successfully updated feedback rating');
        }).catch(err => {
          this.$ktoast.error('Error updating feedback, please try again later', { goAway: 3500 });
          this.$logger.autowarn('Error updating feedback rating', undefined, err);
        }).then(() => {
          this.rating = value;
          this.isDisabled = true;
        });
    },

    getMarkdown(str) {
      return this.$kpurify.sanitise(markdown(str));
    },
  },
};
</script>

<template>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
      <div class="change-password-panel col-md-6 col-sm-12">
        <form @submit.prevent="() => changePassword()">
          <div class="pwd-field-container">
            <div class="form-entry">
              <label for="new-pwd" class="input-label">Enter new password</label>
              <input id="new-pwd" v-model="newPassword"
                class="form-control pwd-field"
                :type="showPassword(viewPwd)"
                pattern=".{12,}"
                title="Password requires 12 or more characters"
              >
              <label for="view-pwd" class="pwd-view-switch">
                <input id="view-pwd" type="checkbox" class="view-pwd" v-model="viewPwd">
                <i class="fas" :class="pwdIcon(viewPwd)"></i>
              </label>
              <div class="invalid-requirements">
                Must contain at least 12 characters or more.
              </div>
            </div>

            <div class="form-entry">
              <label for="confirm-pwd" class="input-label">Confirm password</label>
              <input id="confirm-pwd" v-model="newPasswordConfirm" class="form-control pwd-field" :type="showPassword(viewConfirmPwd)">
              <label for="view-confirm-pwd" class="pwd-view-switch">
                <input id="view-confirm-pwd" type="checkbox" class="view-pwd" v-model="viewConfirmPwd">
                <i class="fas" :class="pwdIcon(viewConfirmPwd)"></i>
              </label>
              <div v-if="this.newPasswordConfirm && this.newPassword !== this.newPasswordConfirm" class="mismatch-requirements">
                <span>Passwords do not match.</span>
              </div>
            </div>
            <div class="password-server-error" v-if="serverError">
              <div v-if="serverErrorCode === 'breached_password'">
                <p class="data-breach-messages">
                This password has used <strong>{{serverError}}</strong> times in various data breach. We highly recommend you use a different password.
                </p>
                <button @click="changePasswordUnsafe" class="btn btn-warning" id="change-password-unsafe-button">Ignore warning</button>
                <p class="data-breach-messages">
                  <a href="https://haveibeenpwned.com/Passwords" target="_blank">Learn more about data breaches</a>
                </p>
              </div>
              <div v-else v-html="serverError"></div>
            </div>
          </div>

          <button class="password-submit-button btn btn-success" :disabled="!validPassword || updateInProgress">
            Confirm <i class="fas fa-spinner fa-spin" v-if="updateInProgress"></i>
          </button>
        </form>
      </div>
      <div class="set-password-guide col-md-6 col-sm-12">
        <h2>How to set a strong password</h2>
        <ul class="password-guide-list">
          <li class="password-guide-item">
            <h4>Use a passphrase consisting of several random words</h4>
            <p>The words should not be related to each other or contain personal information. For example, "correct horse battery staple" or "purple elephant dancing robot". This should be a minimum of 4 words but ideally 6.</p>
          </li>
          <li class="password-guide-item">
            <h4>Don't use common phrases or quotes</h4>
            <p>These can be easily guessed by attackers who use automated tools to crack passwords.</p>
          </li>
          <li class="password-guide-item">
            <h4>Consider using a password manager</h4>
            <p>A password manager will create unique, h4, and random passphrases for you, so you don't have to remember them all.</p>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</template>

<style>
.password-server-error p.data-breach-messages a {
  color: var(--kate-danger);
}

.password-server-error p.data-breach-messages {
  color: var(--kate-danger-dark);
}
</style>

<style scoped>
.password-server-error div {
  display: flex;
  flex-direction: column;
}

.password-server-error div p {
  margin-bottom: 1em;
}

.password-guide-list h4 {
  font-weight: bold;
  margin-bottom: 0;
}

.password-guide-list {
  list-style: none;
  padding-left: 0;
}

.password-guide-item {
  margin-bottom: 1em;
  color: var(--kate-type-light);
}

.pwd-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-entry {
  margin: 10px 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.input-label {
  width: 100%;
}

.form-entry input.pwd-field {
  display: block;
  background-color: var(--input-background);
  color: var(--kate-type-light);
}

.pwd-view-switch {
  cursor: pointer;
  padding: 5px;
  margin: 0;
}

.view-pwd {
  display: none;
}

.pwd-field {
  flex: 1;
}

.mismatch-requirements,
.invalid-requirements,
.password-server-error {
  background-color: var(--kate-danger-v-light);
  border-top: none;
  margin: -1px 28px 15px 0;
  overflow: hidden;
  transition: all 0.2s ease;
  padding: 0 9px;
  color: var(--kate-danger-dark);
  width: 100%;
}

.mismatch-requirements,
.invalid-requirements {
  max-height: 0;
}

.pwd-field:invalid:not(:focus):not(:placeholder-shown) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.pwd-field:invalid:not(:focus):not(:placeholder-shown) ~ .invalid-requirements {
  max-height: 100px;
  padding: 10px;
  margin-top: -1px;
  border: 1px solid var(--kate-danger);
}

.pwd-field:not(:focus):not(:placeholder-shown) ~ .mismatch-requirements,
.pwd-field:invalid:not(:focus):not(:placeholder-shown) ~ .invalid-requirements {
  max-height: 100px;
  padding: 10px;
  margin-top: -1px;
  border: 1px solid var(--kate-danger);
}

</style>

<script>
import ErrorMixin from '../mixins/error-mixins';
import getOrNull from '../modules/get-or-null';

const MIN_PASSWORD_LENGTH = 12;

export default {
  mixins: [ErrorMixin],

  props: {
    token: { type: String, required: true },
  },

  data: () => ({
    newPassword: '',
    newPasswordConfirm: '',
    updateInProgress: false,
    viewPwd: false,
    viewConfirmPwd: false,
    serverErrorCode: '',
    serverError: '',
  }),

  computed: {
    validPassword() {
      return (this.newPassword && this.newPassword.length >= MIN_PASSWORD_LENGTH
        && this.newPassword === this.newPasswordConfirm);
    },
  },

  methods: {
    clear() {
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.serverError = '';
      this.serverErrorCode = '';
    },

    showPassword(show) {
      return show ? 'text' : 'password';
    },

    pwdIcon(show) {
      return show ? 'fa-eye-slash' : 'fa-eye';
    },

    handlePasswordError(err) {
      if (getOrNull('response.status', err) === 400) {
        this.$logger.warn('Password change failed', undefined, err);
        const code = getOrNull('response.data.err', err);
        this.serverErrorCode = code;
        if (code === 'breached_password') {
          this.serverError = this.$kpurify.sanitise(getOrNull('response.data.breach_count', err));
        } else {
          this.serverError = this.$kpurify.sanitise(err.response.data.msg);
        }
      } else {
        this.$logger.error('System error changing password', undefined, err);
        this.showError(err, true);
      }
    },

    changePasswordUnsafe() {
      this.changePassword(true);
    },

    changePassword(unsafe = false) {
      if (!this.validPassword) {
        return;
      }
      this.serverError = '';
      this.serverErrorCode = '';
      this.updateInProgress = true;
      this.$logger.info('Updating password', undefined, true);
      this.$http
        .post('/api/auth/user/password', { password: this.newPassword, token: this.token }, {
          params: {
            confirm_unsafe: unsafe ? 'true' : 'false',
          },
        })
        .then(() => {
          this.$logger.info('Updated password', undefined, true);
          this.$ktoast.success('Successfuly updated password');
          this.clear();
        }).catch(err => {
          this.handlePasswordError(err);
        }).then(() => {
          this.updateInProgress = false;
        });
    },
  },
};
</script>

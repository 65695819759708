<template>
  <div v-if="ready">
    <div class="heading-container content-with-spacing">
      <h1>{{ luMetadata.name }}</h1>
      <div class="heading-buttons">
        <module-asset-completion-button :asset="asset"></module-asset-completion-button>
        <module-lu-open-kloud v-if="kloudEnabled" @open="toggleKloudModal"></module-lu-open-kloud>
      </div>
    </div>
    <div id="module-lu-container" :class="windowWidth <= 900 ? '' : 'content-with-spacing'">
      <div class="panel panel-default">
        <div v-if="lusTreeReady" class="panel-body">
          <splitpanes class="custom-theme" :horizontal="windowWidth <= 900">
            <pane min-size="2.5" max-size="40" :size="30" class="folder" id="tree-container">
              <tree-sidebar @fileclicked="handleFileClick" :tree="refinedTree" :current-filename="currentFilename" :edit-mode="false"></tree-sidebar>
            </pane>
            <pane :size="100" min-size="20" id="file-display-container">
              <file-display v-if="currentFile && !isNotebook" :current-file="currentFile" :module-lu-id="assetId"></file-display>
              <div v-else class="notebook-container">
                <div v-if="kloudEnabled" class="kloud-enabled">
                  <div v-if="!kodRequested">
                    <p>This file cannot be rendered here. Please use the Open Learning Environment button below.</p>
                    <module-lu-open-kloud @open="toggleKloudModal"></module-lu-open-kloud>
                  </div>
                  <template v-else>
                    <kod-loading v-if="!kodReady" @kodReady="kodReady = true"
                    asset-type="module_lu" :asset-id="asset.id" :version="latestVersion"></kod-loading>
                    <kod-landing v-if="kodReady" :asset-name="currentFilename" asset-type="module_lu" :asset-id="asset.id" :version="latestVersion"></kod-landing>
                  </template>
                </div>
                <div class="no-file-display" v-else>
                  <p>KATE is not currently able to display files in this format. Please download the content by clicking the button below and open it locally.</p>
                  <a class="download-button" :href="luDownloadUrl">Download all materials<i class="download-btn fas fa-download"></i></a>
                </div>
              </div>
            </pane>
          </splitpanes>
        </div>
      </div>
    </div>

    <!-- Versions modal -->
    <k-modal v-if="kloudEnabled" :show="showKloudModal" @close="toggleKloudModal">
      <template #header>
        <h3>{{ luMetadata.name }}</h3>
        <p v-if="multipleVersionsAvailable" class="instruction">Multiple versions of this Learning Unit are available. Please select the version
          you would like to work from:
        </p>
        <p v-else class="instruction">Click the link below to open the Learning Unit in the KLOUD learning environment
        </p>
      </template>
      <template #body>
        <ul v-if="savedVersions" class="modal-content-container versions">
          <li v-if="latestVersionIsNew">
            <open-kloud-button
              :asset-id="assetId"
              asset-type="module_lu"
              type="link"
              @open="startKodLoading"
              :version="latestVersion"
              :text="`Get started with the latest version (${latestVersion})`"></open-kloud-button>
          </li>
          <li v-for="version in savedVersions" :key="version.name">
            <open-kloud-button
              :asset-id="assetId"
              asset-type="module_lu"
              type="link"
              :version="version.name"
              :text="`Continue with a previous save on version ${version.name}`"></open-kloud-button>
            <hr>
          </li>
        </ul>
      </template>
      <template #footer>
      </template>
    </k-modal>
  </div>
</template>

<style>
#module-lu-container #tree-container li {
  font-size: 16px;
  padding-bottom: 5px;
}

#module-lu-container .splitpanes__pane,
#module-lu-container .splitpanes.custom-theme .splitpanes__splitter {
  height: 70vh;
}

#module-lu-container .splitpanes.custom-theme.splitpanes--horizontal .splitpanes__splitter {
  height: 10px;
  margin: 5px 0;
}

#module-lu-container .splitpanes--horizontal > .splitpanes__splitter::before {
  margin-top: -2px;
}

#module-lu-container .splitpanes--horizontal > .splitpanes__splitter::after {
  margin-top: 1px;
}

#module-lu-container .splitpanes__pane > div {
  height: 100%;
  overflow-x: auto;
}

#content .heading-container h1 {
  margin: 0;
}

</style>

<style scoped>
.heading-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.heading-container {
  display: flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

#file-display-container {
  padding-left: 15px;
}

#file-display-container > div {
  border: 2px solid var(--kate-background-body-alpha);
  background-color: var(--kate-background-body);
}

#file-display-container > div.notebook-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heading-buttons,
.kloud-enabled {
  position: relative;
}

.kloud-enabled .kloud-launch-button {
  margin-top: 10px;
}

.kloud-launch-button {
  color: var(--kate-type-dark);
  box-shadow: var(--box-shadow);
  margin: 0;
  transition: all 0.3s ease;
  text-align: center;
}

.kloud-launch-button i {
  margin-right: 10px;
}

.download-button {
  background-color: var(--kate-primary);
  border: 1px solid var(--kate-primary);
  color: var(--kate-type-dark);
  border-radius: 4px;
  padding: 8px 25px;
  margin-top: 10px;
  display: inline-block;
}

.download-button:hover {
  background-color: transparent;
  color: var(--kate-primary);
}

.download-button i {
  padding-left: 4px;
}

.kloud-enabled button + .btn-tooltip {
  left: 0;
  margin: 0 auto;
  bottom: -82px;
}

.no-file-display > p {
  padding: 0 15px;
}

/* ==== PDF MODAL CONTENT ==== */
.instruction {
  padding: 15px 0 0;
  margin: 0;
}

.modal-content-container {
  padding: 0;
}

.modal-content-container li {
  list-style: none;
}

.modal-content-container li a {
  cursor: pointer;
  padding: 7px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.modal-content-container li a:hover {
  text-decoration: none;
}

.modal-content-container li a:focus,
.modal-content-container li a:focus span i {
  color: var(--kate-secondary);
}

.modal-content-container li a:hover span {
  text-decoration: underline;
}

.modal-content-container li a span i {
  color: var(--kate-mono-0);
  padding-right: 10px;
}

.modal-content-container li a:hover i {
  color: var(--kate-secondary);
}

@media (max-width: 900px) {
  #module-lu-container .panel-body {
    height: 70vh;
  }

  .heading-container {
    margin-top: 0 !important;
  }

  #file-display-container {
    padding-left: 0;
  }
}

</style>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import { getNestedFiles } from '../modules/nested-files';
import ErrorMixin from '../mixins/error-mixins';
import TreeSidebar from '../ide/tree-sidebar.vue';
import KModal from '../components/k-modal.vue';
import FileDisplay from './k-file-display.vue';
import ModuleAssetCompletionButton from './module-asset-completion-button.vue';
import OpenKloudButton from '../components/open-kloud-button.vue';
import KodLanding from '../profile/kod-landing.vue';
import KodLoading from '../profile/kod-loading.vue';
import ModuleLuOpenKloud from './module-lu-open-kloud.vue';

const ACCEPTABLE_FORMATS = [
  'ipynb',
  'pdf',
];

export default {
  components: {
    Splitpanes,
    Pane,
    KModal,
    TreeSidebar,
    FileDisplay,
    ModuleAssetCompletionButton,
    OpenKloudButton,
    KodLanding,
    KodLoading,
    ModuleLuOpenKloud,
  },

  mixins: [ErrorMixin],

  props: {
    moduleDetails: {
      type: Object,
    },
    sidebarReady: {
      type: Boolean,
    },
    asset: {
      type: Object,
    },
  },

  data() {
    return {
      currentFile: undefined,
      lusTreeReady: false,
      luMetadataReady: false,
      tree: [],
      luMetadata: {},
      showKloudModal: false,
      userSavedVersions: [],
      windowWidth: 0,
      kodRequested: false,
      kodReady: false,
    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  beforeMount() {
    this.$Loading.start();
    this.getLuData();
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
    assetId() {
      this.getLuData();
    },
  },

  computed: {
    ready() {
      return this.luMetadataReady && this.lusTreeReady && this.sidebarReady;
    },

    assetId() {
      return this.asset?.id;
    },

    kloudEnabled() {
      return this.luMetadata.kloud_compatible && this.moduleDetails.kloud_enabled;
    },

    validFiles() {
      if (!this.tree) {
        return [];
      }
      return this.tree.filter(file => file.type !== 'tree'
        && !file.name.startsWith('.')
        && !file.path.startsWith('.')
        && ACCEPTABLE_FORMATS.indexOf(this.getFileExtension(file.name)) !== -1);
    },

    refinedTree() {
      const files = this.validFiles.map(file => file.path.split('/'));
      return getNestedFiles(files, '.');
    },

    currentFilename() {
      if (this.currentFile === undefined) {
        return undefined;
      }
      return this.currentFile.path;
    },

    kloudUrl() {
      if (this.kloudEnabled && this.luMetadata?.id) {
        return `/api/curriculum/kloud/lu/${this.luMetadata.id}/spin`;
      }
      return undefined;
    },

    moduleId() {
      return parseInt(this.$route.params.moduleId, 10);
    },

    isNotebook() {
      return this.currentFile.name.slice(-6) === '.ipynb';
    },

    latestVersion() {
      return this.luMetadata?.version;
    },

    latestVersionIsNew() {
      return !this.userSavedVersions.includes(this.latestVersion);
    },

    savedVersions() {
      return [...this.userSavedVersions].sort().reverse().map(x => ({
        name: x,
        spinUrl: `${this.kloudUrl}?version=${x}`,
      }));
    },

    multipleVersionsAvailable() {
      if (this.savedVersions.length > 1 || (this.latestVersionIsNew && this.savedVersions.length > 0)) {
        return true;
      }
      return false;
    },

    luDownloadUrl() {
      if (this.luMetadata && this.luMetadata.id) {
        return `/api/curriculum/learning_unit/${this.luMetadata.id}/zip`;
      }
      return undefined;
    },

    firstPdf() {
      for (let i = 0; i < this.validFiles.length; i++) {
        if (this.validFiles[i].name.slice(-4) === '.pdf') {
          return this.validFiles[i];
        }
      }
      return undefined;
    },

    firstFile() {
      if (this.firstPdf === undefined) {
        return this.validFiles[0].path;
      }
      return this.firstPdf.path;
    },
  },

  methods: {
    startKodLoading() {
      this.kodRequested = true;
      this.showKloudModal = false;
    },

    setCurrentFile(filepath) {
      this.currentFile = this.validFiles.find(x => x.path === filepath);
    },

    getFileExtension(filename) {
      return filename.split('.').pop();
    },

    handleFileClick(payload) {
      this.setCurrentFile(payload);
    },

    getLuData() {
      if (this.assetId) {
        this.getLuMetadata();
        this.getLuTree();
      }
    },

    getLuTree() {
      this.$logger.info('Getting LU tree content', { module_lu_id: this.assetId }, true);
      this.$http.get(`/api/curriculum/learning_unit/${this.assetId}/tree`)
        .then(response => {
          this.tree = response.data.tree;
          this.setCurrentFile(this.firstFile);
          this.$logger.info('Got LU tree content', { module_lu_id: this.assetId });
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.autowarn('Error getting LU tree content', { module_lu_id: this.assetId }, err);
            this.showError(err, true);
          } else {
            this.$logger.warn('No tree content found for LU');
          }
        }).then(() => {
          this.lusTreeReady = true;
        });
    },

    getLuMetadata() {
      this.$logger.info('Getting LU metadata', { module_lu_id: this.assetId }, true);
      this.$http.get(`/api/curriculum/learning_unit/${this.assetId}`)
        .then(response => {
          this.luMetadata = response.data;
          this.$logger.info('Got LU metadata', { module_lu_id: this.assetId });
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.autowarn('Error getting LU metadata', { module_lu_id: this.assetId }, err);
            this.showError(err, true);
          } else {
            this.$logger.warn('No metadata found for LU', { module_lu_id: this.assetId });
          }
        }).then(() => {
          this.luMetadataReady = true;
        });
    },

    getUserSavedVersions() {
      this.$logger.info('Getting LU saved versions on KLOUD', { assetId: this.assetId }, true);
      this.$Loading.minimal();
      return this.$http.get(`/api/curriculum/kloud/lu/${this.assetId}/saved-versions`)
        .then(response => {
          this.$logger.info('Got LU saved versions on KLOUD', { assetId: this.assetId });
          this.userSavedVersions = response.data.saved_versions;
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.autowarn('Error getting saved versions of LU', { assetId: this.assetId }, err);
            this.userSavedVersions = [];
            this.showError(err, true);
          } else {
            this.$logger.warn('No saved versions found for LU', { module_lu_id: this.assetId });
          }
        }).then(() => {
          this.$Loading.finish();
        });
    },

    toggleKloudModal() {
      if (this.showKloudModal) {
        // Modal is open - close it
        this.showKloudModal = !this.showKloudModal;
      } else {
        // Modal is closed - retrieve versions then open it
        this.getUserSavedVersions().then(() => {
          this.showKloudModal = !this.showKloudModal;
        });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
